import { gql } from '@apollo/client';

export const USER_PRODUCTS = gql`
  query USER_PRODUCTS {
    UserProducts {
      _id
      name
      type
      ifSponsored
      ifVisible
      size
      ingredients
      category
      orderType
      deliveryTime
      deliverability
      externalId
      image {
          url
          name
      }
      externalInfo {
        ingredients
        category
      }
    }
  }
`;


