import { ReactElement, useState } from "react";

interface ModalProps {
    title?: string;
    body: ReactElement;
    showModal: boolean;
    handleShowModal: (show: boolean) => void
  }

export const Modal = (props: ModalProps) => {
    return (
      <>
        {props.showModal ? (
          <>
            <div
              className="mt-12 justify-center items-center flex overflow-x-hidden overflow-y-auto absolute inset-6 z-50 outline-none focus:outline-none"
            >
              <div className="mt-20 relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      {props.title}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-60 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => props.handleShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-60 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        x
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div className="my-4 text-slate-500 text-lg leading-relaxed">
                        {props.body}
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => props.handleShowModal(false)}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-1 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }