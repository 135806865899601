import { ReactNode, useState } from "react";
import { LocalProduct, Product, ProductOrderTypes } from "../../storage/types";
import { LOG } from "../../config";
import { Textfit } from "react-textfit";
import { calculateKgOfProduct, calculateNumberOfProducts, round2Decimals, transformDeltaFromMidnightToString, translateOrderType } from "../../libs/toolbox";
import { useDispatch, useSelector } from "react-redux";
import { addPreferredProduct, decreaseDecimalProductQuantity, decreaseProductQuantity, increaseDecimalProductQuantity, increaseProductQuantity, removePreferredProduct, selectShop } from "../../storage/redux/shopSlice";
import { Modal } from "../../ui/Modal";

const log = LOG.extend('ProductComponent')

type InnerCardProps = {
    product: LocalProduct
    onSelect: () => void
    isForDesktop: boolean
}

export const InnerCard = (props: InnerCardProps) => {
    const shop = useSelector(selectShop);
    const orderDay = new Date(shop.orderDay)
    const now = new Date()
    const delta = Math.floor(Math.floor(now.getTime() - orderDay.getTime())/1000)/60
    const deliverability = (props.product.deliverability)?props.product.deliverability:0
    const isOrderable = (delta < deliverability)
    const dispatch = useDispatch();
    const productQuantityIncrease = () => {
        log.debug('ProductQuantity increase request')
        dispatch(increaseProductQuantity(props.product))
    }

    const productQuantityDecrease = () => {
        log.debug('ProductQuantity decrease request')
        dispatch(decreaseProductQuantity(props.product))
    }

    const productQuantityIncreaseDecimal = () => {
        log.debug('ProductQuantity increase decimal request')
        dispatch(increaseDecimalProductQuantity(props.product))
    }

    const productQuantityDecreaseDecimanl = () => {
        log.debug('ProductQuantity decrease decimal request')
        dispatch(decreaseDecimalProductQuantity(props.product))
    }

    const shopAddPreferredProduct = () => {
        log.debug('Add preferred product request')
        dispatch(addPreferredProduct(props.product._id))
    }

    const shopRemovePreferredProduct = () => {
        log.debug('Remove preferred product request')
        dispatch(removePreferredProduct(props.product._id))
    }

    const togglePreferredProduct = () => {
        if (!shop.preferredProducts) {
            shopAddPreferredProduct()
            return
        }
        if (shop.preferredProducts.includes(props.product._id)) {
            shopRemovePreferredProduct()
        } else {
            shopAddPreferredProduct()
        }
    }

    // console.log(props.product)
    const imageUrl = props.product.image?.url || "/images/icon-192.png"
    const productQuantity =  calculateNumberOfProducts(props.product.size, props.product.quantityTot, props.product.orderType || ProductOrderTypes.unknown)
    const orderType = props.product.orderType || ProductOrderTypes.unknown
    const productQuantityKg = calculateKgOfProduct(props.product.size, props.product.quantityTot, props.product.orderType || ProductOrderTypes.unknown)

    const isPreferredFill = (shop.preferredProducts && shop.preferredProducts.includes(props.product._id)) ? "orange" : "gray"

    const card = (!props.isForDesktop) ? <div className="flex">
    <div className="flex rounded-lg h-28 w-28 p-2" onClick={() => {props.onSelect()}}>
        {/* imagine */}
        <img className="h-26 w-26 rounded-lg object-cover" src={imageUrl} alt={props.product.name} />
    </div>
    <div className="flex-1 h-28 overflow-hidden">
        <div className="flex w-full h-12 overflow-y-auto items-center">
            <div className="w-full truncate uppercase text-center" onClick={() => {props.onSelect()}}>
                {props.product.name}
            </div>
            <div className="w-8 cursor-pointer" onClick={togglePreferredProduct}>
                <svg className="w-8 h-8" fill={isPreferredFill} stroke="" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                </svg>
            </div>
        </div>
        <div className="grid grid-cols-3 gap-2 h-16">
            <div className="grid content-center h-16">
                <div>
                    gr./pz. 
                </div>
                <div className="overflow-hidden">
                    {props.product.size} gr.
                </div>
            </div>
            <div className="flex flex-col text-lg h-16 overflow-hidden">
                <div>
                    {(orderType === ProductOrderTypes.kg && productQuantity > 0)? `~${productQuantity}` : productQuantity}
                </div>
                <div>
                    {(orderType === ProductOrderTypes.piece && productQuantity > 0)? `~${productQuantityKg} Kg` : `${productQuantityKg} Kg`}
                </div>
            </div>
            {(isOrderable)? <div className="grid grid-cols-2 gap-2 content-center h-16">
                <div className="flex flex-col items-center">
                    <div className="text-xs overflow-hidden h-4">
                        1x pz.
                    </div>
                    <div onClick={productQuantityIncreaseDecimal}>
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" /></svg>
                    </div>
                    <div onClick={productQuantityDecreaseDecimanl}>
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" /></svg>
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <div className="text-xs overflow-hidden h-4">
                        5x pz.
                    </div>
                    <div onClick={productQuantityIncrease}>
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" /></svg>
                    </div>
                    <div onClick={productQuantityDecrease}>
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" /></svg>
                    </div>
                </div>                            
            </div>: "NON ORDINABILE"
            }
        </div>
    </div>
</div> : <div className="flex flex-col">
                <div className="flex justify-end">
                    <div className="flex justify-end cursor-pointer" onClick={togglePreferredProduct}>
                        <svg className="w-8 h-8" fill={isPreferredFill} stroke="" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                        </svg>
                    </div>

                    <div className="pt-2 pr-2">
                        <div className="flex justify-end" onClick={() => {props.onSelect()}}>
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                        </div>
                    </div>
                </div>
                <div className="p-2 h-36 w-full flex justify-center rounded-lg" onClick={() => {props.onSelect()}}>
                    {/* imagine */}
                    <img className="rounded-lg object-cover" src={imageUrl} alt={props.product.name} />
                </div>
                <div className="flex items-center text-center h-10 space-x-2 pt-2">
                    {/* <Textfit className="w-full uppercase" mode="single" forceSingleModeWidth={false}> */}
                        {props.product.name}
                    {/* </Textfit> */}
                </div>
                <div className="text-center">
                    Grammatura: {props.product.size} gr./pezzo
                </div>
                <div className="text-center">
                    In ordine ca.: {(orderType === ProductOrderTypes.kg)? `${productQuantity} pz.`: `${productQuantityKg} kg`}
                </div>
                <div className="text-center">
                    <div>
                        Corrispondenti a
                    </div>
                    <div className="font-semibold">
                        {(orderType === ProductOrderTypes.kg)? `${productQuantityKg} kg`:`${productQuantity} pz`}
                        {/* {round2Decimals(props.product.quantityTot)} Kg */}
                    </div>
                </div>
                <div className="text-center pb-4">
                    {(isOrderable)? <div className="grid grid-cols-2 content-center space-x-2">
                            <div className="">
                                1x pz.
                            </div>
                            <div className="">
                                5x pz.
                            </div>
                            <div className="flex justify-around">
                                <button onClick={productQuantityDecreaseDecimanl} >
                                    <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" /></svg>
                                </button>
                                <button onClick={productQuantityIncreaseDecimal} >
                                    <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" /></svg>
                                </button>
                            </div>
                            <div className="flex justify-around">
                                <button onClick={productQuantityDecrease} >
                                    <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" /></svg>
                                </button>
                                <button onClick={productQuantityIncrease}>
                                    <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" /></svg>
                                </button>
                            </div>
                        </div>: <div className="pt-4 text-orange-500">{(props.product?.deliverability)?'Ordinabile fino '+transformDeltaFromMidnightToString(props.product?.deliverability):"NON ORDINABILE"}</div>
                    }
                </div>
            </div>

    return card
}

// export const InnerCardDesktop = (props: InnerCardProps) => {
//     const shop = useSelector(selectShop);
//     const orderDay = new Date(shop.orderDay)
//     const now = new Date()
//     const delta = Math.floor(Math.floor(now.getTime() - orderDay.getTime())/1000)/60
//     const deliverability = (props.product.deliverability)?props.product.deliverability:0
//     const isOrderable = (delta < deliverability)
//     const dispatch = useDispatch();
//     const productQuantityIncrease = () => {
//         log.debug('ProductQuantity increase request')
//         dispatch(increaseProductQuantity(props.product))
//     }

//     const productQuantityDecrease = () => {
//         log.debug('ProductQuantity decrease request')
//         dispatch(decreaseProductQuantity(props.product))
//     }

//     const productQuantityIncreaseDecimal = () => {
//         log.debug('ProductQuantity increase decimal request')
//         dispatch(increaseDecimalProductQuantity(props.product))
//     }

//     const productQuantityDecreaseDecimanl = () => {
//         log.debug('ProductQuantity decrease decimal request')
//         dispatch(decreaseDecimalProductQuantity(props.product))
//     }

//     const shopAddPreferredProduct = () => {
//         log.debug('Add preferred product request')
//         dispatch(addPreferredProduct(props.product._id))
//     }

//     const shopRemovePreferredProduct = () => {
//         log.debug('Remove preferred product request')
//         dispatch(removePreferredProduct(props.product._id))
//     }

//     const togglePreferredProduct = () => {
//         if (!shop.preferredProducts) {
//             shopAddPreferredProduct()
//             return
//         }
//         if (shop.preferredProducts.includes(props.product._id)) {
//             shopRemovePreferredProduct()
//         } else {
//             shopAddPreferredProduct()
//         }
//     }

//     const imageUrl = props.product?.image?.url || "/images/icon-192.png"

//     const productQuantity =  calculateNumberOfProducts(props.product.size, props.product.quantityTot, props.product.orderType || ProductOrderTypes.unknown)
//     const orderType = props.product.orderType || ProductOrderTypes.unknown
//     const productQuantityKg = calculateKgOfProduct(props.product.size, props.product.quantityTot, props.product.orderType || ProductOrderTypes.unknown)

//     const isPreferredFill = (shop.preferredProducts && shop.preferredProducts.includes(props.product._id)) ? "orange" : "gray"

//     return <div className="flex flex-col">
//                 <div className="flex justify-end">
//                     <div className="flex justify-end cursor-pointer" onClick={togglePreferredProduct}>
//                         <svg className="w-8 h-8" fill={isPreferredFill} stroke="" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
//                             <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
//                         </svg>
//                     </div>

//                     <div className="pt-2 pr-2">
//                         <div className="flex justify-end" onClick={() => {props.onSelect()}}>
//                             <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="p-2 h-36 w-full flex justify-center rounded-lg" onClick={() => {props.onSelect()}}>
//                     {/* imagine */}
//                     <img className="rounded-lg object-cover" src={imageUrl} alt={props.product.name} />
//                 </div>
//                 <div className="flex items-center text-center h-10 space-x-2 pt-2">
//                     <Textfit className="w-full uppercase" mode="single" forceSingleModeWidth={false}>
//                         {props.product.name}
//                     </Textfit>
//                 </div>
//                 <div className="text-center">
//                     Grammatura: {props.product.size} gr./pezzo
//                 </div>
//                 <div className="text-center">
//                     In ordine ca.: {(orderType === ProductOrderTypes.kg)? `${productQuantity} pz.`: `${productQuantityKg} kg`}
//                 </div>
//                 <div className="text-center">
//                     <div>
//                         Corrispondenti a
//                     </div>
//                     <div className="font-semibold">
//                         {(orderType === ProductOrderTypes.kg)? `${productQuantityKg} kg`:`${productQuantity} pz`}
//                         {/* {round2Decimals(props.product.quantityTot)} Kg */}
//                     </div>
//                 </div>
//                 <div className="text-center pb-4">
//                     {(isOrderable)? <div className="grid grid-cols-2 content-center space-x-2">
//                             <div className="">
//                                 1x pz.
//                             </div>
//                             <div className="">
//                                 5x pz.
//                             </div>
//                             <div className="flex justify-around">
//                                 <button onClick={productQuantityDecreaseDecimanl} >
//                                     <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" /></svg>
//                                 </button>
//                                 <button onClick={productQuantityIncreaseDecimal} >
//                                     <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" /></svg>
//                                 </button>
//                             </div>
//                             <div className="flex justify-around">
//                                 <button onClick={productQuantityDecrease} >
//                                     <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" /></svg>
//                                 </button>
//                                 <button onClick={productQuantityIncrease}>
//                                     <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" /></svg>
//                                 </button>
//                             </div>
//                         </div>: <div className="pt-4 text-orange-500">{(props.product?.deliverability)?'Ordinabile fino '+transformDeltaFromMidnightToString(props.product?.deliverability):"NON ORDINABILE"}</div>
//                     }
//                 </div>
//             </div>
// }

interface ProductCardProps {
    children?: ReactNode;
    product: LocalProduct
    onInfo: (product:LocalProduct) => void
    bgColor?: string
    isForDesktop: boolean
}

export const ProductCard = (props: ProductCardProps) => {
    const productData = props.product
    const [selectedProduct, setSelectedProduct] = useState<LocalProduct | null>(null)
    // console.log(`Selected ${selectedProduct?.name}`)
    const bgColor = props.bgColor || "bg-white"
    const card = (!props.isForDesktop) ? <div key={productData._id} className={`${bgColor} h-28 mx-2 my-2 shadow-2xl rounded-lg space-y-2`}>
        <InnerCard product={productData} onSelect={() => {props.onInfo(productData)}} isForDesktop={props.isForDesktop}/>
    </div> : <div key={productData._id} className={`container ${bgColor} shadow-2xl rounded-lg`}>
        <InnerCard product={productData} onSelect={() => {props.onInfo(productData)}} isForDesktop={props.isForDesktop}/>
    </div>
    return card

}

// export const ProductCardDesktop = (props: ProductCardProps) => {
//     const productData = props.product
//     const [selectedProduct, setSelectedProduct] = useState<LocalProduct | null>(null)
//     // console.log(`Selected ${selectedProduct?.name}`)
//     const bgColor = props.bgColor || "bg-white"
//     return <div key={productData._id} className={`container ${bgColor} shadow-2xl rounded-lg`}>
//         <InnerCard product={productData} onSelect={() => {props.onInfo(productData)}} isForDesktop={true}/>
//     </div>

// }