import {useEffect, useState} from 'react'
import { useLazyQuery, useQuery } from "@apollo/client";
import { USER_LAST_ORDERS, USER_ORDER } from "../../storage/queries/order";
import { LocalOrder, LocalProduct, Product, ProductOrderTypes, UserOrder } from "../../storage/types";
import { Button, Loading } from "../../ui";
import { calculateKgOfProduct, calculateNumberOfProducts, canOrderFrom, printDate, transformProductOrderTypes, YYYYMMDDtoDate } from '../../libs/toolbox';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeOrderDay, selectShop, setShopTomorrowOrder } from '../../storage/redux/shopSlice';
import { Modal } from '../../ui/Modal'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

type PrintOrderProps = {
  order: UserOrder
  notPresentsProducts: LocalProduct[]
}

const PrintOrder = (props: PrintOrderProps) => {
  const [isModelOpen, setIsModalOpen] = useState(false)
  const order = props.order
  const notPresentsProducts = props.notPresentsProducts
  const warning = <div className='pl-1 text-orange-500' onClick={() => setIsModalOpen(true)}>
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
  </div>
  const products = (order && order.products)? 
      (order.products.length === 0)? 
          <div className="text-orange-500">Nessun prodotto presente</div>:
          <div className='grid grid-cols-1 items-center justify-items-center'>
          {order.products.map((orderProduct) => {
              console.log(orderProduct)
              const productQuantity = calculateNumberOfProducts(orderProduct.product.size, orderProduct.quantity, orderProduct.product.orderType || ProductOrderTypes.unknown)
              const productQuantityInKg = calculateKgOfProduct(orderProduct.product.size, orderProduct.quantity, orderProduct.product.orderType || ProductOrderTypes.unknown)
              const isNotPresentIndex = notPresentsProducts.findIndex((product) => product._id.toString() === orderProduct.product._id.toString())
              return <div className='flex' key={orderProduct.product._id}>
                  {productQuantity !== Infinity? productQuantity:""} {orderProduct.product.name} - {productQuantityInKg} kg {(isNotPresentIndex !== -1)? warning:null}
                  </div>   
              })
          }
          </div> : <div className="text-red-500">Qualcosa e' andato storto</div>
  const exceptionsProducts = (order && order.exceptionsProducts)? 
  (order.exceptionsProducts.length === 0)? 
      <div className="text-orange-500">Nessun prodotto presente</div>:
      <div className='grid grid-cols-1 items-center justify-items-center'>
      {order.exceptionsProducts.map((orderProduct) => {
          const productQuantity = calculateNumberOfProducts(orderProduct.product.size, orderProduct.quantity, orderProduct.product.orderType || ProductOrderTypes.unknown)
          const isNotPresentIndex = notPresentsProducts.findIndex((product) => product._id.toString() === orderProduct.product._id.toString())
          return <div className='flex' key={orderProduct.product._id}>{productQuantity !== Infinity? productQuantity:""} {orderProduct.product.name} - {transformProductOrderTypes(orderProduct.product.orderType, orderProduct.quantity)} {(isNotPresentIndex !== -1)? warning:null}</div>   
          })
      }
      </div> : <div className="text-red-500">Qualcosa e' andato storto</div>
  const arr1 = order.products || []
  const arr2 = order.exceptionsProducts || []
  const totProducts : {
      product: Product;
      quantity: number;
  }[] = Object.values([...arr1, ...arr2].reduce((acc : any, curr) => {
      if(!acc[curr.product._id]) {
        acc[curr.product._id] = curr;
      } else {
        const newQuantity = acc[curr.product._id].quantity + curr.quantity;
        acc[curr.product._id] = {...acc[curr.product._id], quantity: newQuantity}
      }
      return acc;
   }, {}));
   const allProducts = (totProducts)? 
   (totProducts.length === 0)? 
       <div className="text-orange-500">Nessun prodotto presente</div>:
       <div className='grid grid-cols-1 items-center justify-items-center'>
       {totProducts.map((orderProduct) => {
          const isNotPresentIndex = notPresentsProducts.findIndex((product) => product._id.toString() === orderProduct.product._id.toString())
          const productQuantity = calculateNumberOfProducts(orderProduct.product.size, orderProduct.quantity, orderProduct.product.orderType || ProductOrderTypes.unknown)
           return <div className='flex' key={orderProduct.product._id}>{productQuantity !== Infinity? productQuantity:""} {orderProduct.product.name} - {transformProductOrderTypes(orderProduct.product.orderType, orderProduct.quantity)} {(isNotPresentIndex !== -1)? warning:null}</div>   
           })
       }
       </div> : <div className="text-red-500">Qualcosa e' andato storto</div>
  return (<>
          <Modal
            showModal={isModelOpen}
            handleShowModal={setIsModalOpen}
            title="Info Prodotto"
            body={<div className="">
              <div className='flex justify-end'>
                <div className='w-full text-center text-2xl'>Prodotto non presente in app</div>
              </div>
              <div className='grid grid-cols-1 justify-items-center pt-2 text-center'>
                <div>
                I prodotti contrassegnati con l'icona
                </div>
                <div className='pl-1 text-orange-500'>
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                </div> 
                <div>
                non sono attualmente gestibili in app
                </div>
                <div className='pt-2'>
                  Non è quindi possibile diminuire o aumentare la quantità di tale prodotto
                </div>
                <div className='pt-2'>
                  Per modificarne la quantità contattare Pandoper
                </div>
              </div>
            </div>
            }
          />
        <div>
          <div>
              {allProducts}
          </div>
          <div className="font-bold pt-4">Da ordine ricorrente</div>
          <div>
              {products}
          </div>
          <div className="font-bold">Solamente per {printDate(YYYYMMDDtoDate(order.date))}</div>
          <div>
              {exceptionsProducts}
          </div>
      </div>
      </>
  )
}

interface DashViewProps {
  route?: any;
}


const DashView = (props: DashViewProps) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const shop = useSelector(selectShop);
  const [isModelOpen, setIsModalOpen] = useState(false)
  const [value, onChange] = useState(new Date());
  const timelimit = new Date()
  timelimit.setHours(0,0,0,0)
  timelimit.setTime(timelimit.getTime() + (2*60*60*1000));
  console.log('timelimit', timelimit)
  console.log('now', new Date())
  console.log('orderDay', new Date(shop.orderDay))
  const lastDayToShow = new Date()
  lastDayToShow.setDate(timelimit.getDate() + 6);

  const [loadOrder,{called, loading: loadingOrder, data: dataOrder, error}] = useLazyQuery<{ UserOrder: UserOrder }>(USER_ORDER, {
    fetchPolicy: 'network-only',
    variables: {
      date: new Date(shop.orderDay).toISOString()
    },
  });

  const [loadLastOrders,{called: calledLastOrders, loading: loadingLastOrders, data: dataLastOrders, error: errorLastOrders}] = useLazyQuery<{ UserLastOrders: LocalOrder[] }>(USER_LAST_ORDERS, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 5
    },
  });

  useEffect(() => {
    console.log('loadOrder')
    loadOrder();
    loadLastOrders()
  }, [shop.orderDay]);

  console.log('dataOrder', dataOrder)
  console.log('dataLastOrders', dataLastOrders)

  const order = (dataOrder && dataOrder.UserOrder && dataOrder.UserOrder.products && dataOrder.UserOrder.exceptionsProducts)?
    (dataOrder.UserOrder.products.length === 0) ? <div className="text-orange-500">Nessun prodotto presente</div>:<div>
    {dataOrder.UserOrder.products.map((orderProduct) => {
        return <div key={orderProduct.product._id}>{transformProductOrderTypes(orderProduct.product.orderType, orderProduct.quantity)} - {orderProduct.product.name}</div>   
        })
    }
  </div> : null

  // if(dataOrder) {
  //   dispatch(setShopTomorrowOrder({order: dataOrder.UserOrder}))
  // }

  const openingLabel = (!dataOrder || !dataOrder.UserOrder)? null : 
    (dataOrder.UserOrder.ifClosed === true)? 
        <div className="text-orange-500 text-sm">Il tuo locale è chiuso, non riceverai ordini</div> : 
        "" ;

  if (error) return <div className="text-red-500 text-sm">
    <div>
        Attenzione: qualcosa non va
    </div>
    <div className="text-xs text-red-300">
        {error.message}
    </div>
  </div>

  console.log('order', order)
  console.log('shop', shop)
  function closeModal() {
    setIsModalOpen(false)
  }  

  const increaseOrderDay = () => {
    const newDate = new Date(shop.orderDay)
    newDate.setDate(new Date(shop.orderDay).getDate() + 1);
    dispatch(changeOrderDay(newDate.toDateString()))
    // setOrderDay(newDate)
  }
  const decreaseOrderDay = () => {
      const newDate = new Date(shop.orderDay)
      newDate.setDate(new Date(shop.orderDay).getDate() - 1);
      dispatch(changeOrderDay(newDate.toDateString()))
      // setOrderDay(newDate)
  }

  const updateOrder = () => {
    if (dataOrder) {
      console.log('updating order...')
      console.log(dataOrder.UserOrder)
      dispatch(setShopTomorrowOrder({order: dataOrder.UserOrder}))
    }
    navigate('/products/all')
  }

  const ifShowDayBefore = (shopOrderDay: string) => {
    // limite di ore nella giornata (1 -> fino all'1 di notte)
    const limitHours = 1
    const now = new Date()
    const orderDay = new Date(shopOrderDay)
    const theDayAfterTomorrow = new Date()
    const endOfToday = new Date()
    const timelimit = new Date()
    endOfToday.setHours(24,0,0,0)
    theDayAfterTomorrow.setDate(theDayAfterTomorrow.getDate() + 2)
    theDayAfterTomorrow.setHours(0,0,0,0)
    timelimit.setHours(0,0,0,0)
    timelimit.setTime(timelimit.getTime() + (limitHours*60*60*1000));
    // se l'ordine e' per dopodomani allora posso sempre tornare indietro a domani
    if (orderDay >= theDayAfterTomorrow) return true
    // se l'ordone e' per oggi allora non posso mai tornare indietro
    if (orderDay < endOfToday) return false
    if (now < timelimit) return true
    return false
  }

  const calendarMinDate = (canOrderFrom(new Date()))? new Date(): new Date(new Date().setDate(new Date().getDate() + 1))
  
  const lastLocalOrders = (dataLastOrders && dataLastOrders.UserLastOrders)? 
    dataLastOrders.UserLastOrders.map((localOrder) => <div>
    In data {new Date(localOrder.createdAt).toLocaleString()}  ordinati {localOrder.order.products.length} Prodotti
  </div>) : null

  return (
      <>
        <Modal
          title='Info Dashboard'
          body={<>
          <div className='pt-2'>
            <div className='font-medium'>
              In questa sezione puoi vedere quali prodotti riceverai nell'ordine per la data selezionata.
            </div>
            <div className='pt-1'>
              Puoi selezionare un giorno diverso utilizzando le frecce che trovi a fianco della data.
            </div>
            <div className='pt-1'>
              Questi prodotti sono la somma dei prodotti che ricevi <label className='font-bold'>da ordine ricorrente</label> ed i prodotti che ricevi <label className='font-bold'>solamente per la data selezionata</label>
            </div>
          </div>
          <div className='pt-4'>
                <div className='font-medium'>
                  Da ordine ricorrente
                </div>
                <div>
                  Sono i prodotti che ricevi tutti i giorni
                </div>
              </div>
              <div className='pt-2'>
                <div className='font-medium'>
                  Solamente (seguito dalla data)
                </div>
                <div>
                  Sono i prodotti che hai chiesto di ricevere solamente per la data indicata
                </div>
              </div>
              <div className='pt-4'>
                <div className='font-medium'>
                  MODIFICA ORDINE
                </div>
                <div>
                  Cliccando sul pulsante in basso, puoi accedere alla sezione di modifica ordine, dalla quale potrai modificare l'ordine.
                </div>
              </div>
        </>}
          showModal={isModelOpen}
          handleShowModal={setIsModalOpen}
        />
        <div className='h-full'>
          <div className='h-full sm:grid sm:grid-cols-2'>
            <div className="pt-2">
              <div className="text-center">
                {openingLabel}
                <div className='font-bold text-xl pt-2 flex justify-end'>
                  <div className='flex w-full justify-between px-2'>
                    <div>
                      {ifShowDayBefore(shop.orderDay)? 
                        <svg onClick={decreaseOrderDay} className="pr-2 w-8 h-8 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" /></svg>:
                        null}
                    </div>
                    <div>
                      {printDate(new Date(shop.orderDay))} riceverai
                    </div>
                    <div className='sm:hidden'>
                      {(new Date(shop.orderDay) < lastDayToShow)? 
                        <svg onClick={increaseOrderDay} className="pl-2 w-8 h-8 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>:
                        null}
                    </div>
                    <div className='hidden sm:flex'>
                      <svg onClick={increaseOrderDay} className="pl-2 w-8 h-8 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
                    </div>
                  </div>
                  <div className="flex justify-end" onClick={() => {setIsModalOpen(true)}}>
                      <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                  </div>
                </div>
                {loadingOrder || !dataOrder? <Loading/> : 
                    <div className="flex flex-col w-full  space-x-2">
                        {(order)? <PrintOrder order={dataOrder.UserOrder} notPresentsProducts={shop.notPresentsProducts} />: "Nessun ordine"}
                        {/* <Button disabled={loadingOrder} full color="blue" onClick={() => loadOrder()}>Carica ordine domani</Button> */}
                    </div>
                }
              </div>
            </div>
            <div className='hidden sm:block h-full'>
              <div className='h-1/2 grid content-center justify-center'>
                <Calendar 
                  onChange={(val: Date) => dispatch(changeOrderDay(val.toDateString()))} 
                  value={new Date(shop.orderDay)}
                  minDate={calendarMinDate}
                  />
              </div>
              <div className='h-1/2 space-y-2 text-center'>
                <div className='pb-2'>
                  Ultimi 5 ordini
                </div>
                {lastLocalOrders}
              </div>
            </div>
          </div>
          <div className="grid justify-items-center fixed bottom-2 w-full px-2">              
            <Button className="max-w-md px-2" size='lg' full color="green" shadow onClick={updateOrder}>Modifica Ordine {printDate(new Date(shop.orderDay))}</Button>
          </div>
        </div>
      </>
  );
};

export { DashView };
