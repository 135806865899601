import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OrderTypeCalendar, OrderTypeSelect } from "../../components/Order/OrderTypeSelect";
import { calculateNumberOfProducts, canOrderFrom, getJustTomorrow, transformProductOrderTypes } from "../../libs/toolbox";
import { newOrderData, newOrderDaysOfTheWeekData, newOrderDaysOfTheWeekVariables, newOrderJustOneDayData, newOrderJustOneDayVariables, newOrderVariables, NEW_ORDER, NEW_ORDER_DAYS_OF_THE_WEEK, NEW_ORDER_JUST_ONE_DAY } from "../../storage/queries/order";
import { resetCartProductQuantity, resetProductQuantity, selectShop } from "../../storage/redux/shopSlice";
import { LocalProduct, ProductOrderTypes } from "../../storage/types";
import { Button, Loading } from "../../ui";
import { CONFIG } from '../../config';
import { Modal } from "../../ui/Modal";
import { TextArea } from "../../ui/TextArea";
const envMode = CONFIG.mode


interface ProductShopInfoProps {
    route?: any;
    notPresentInApp?: boolean;
    openModal: () => void;
    product: LocalProduct
  }

const productColor = (product: LocalProduct): "text-green-500" | "text-black" | "text-red-500" => {
    let color : "text-green-500" | "text-black" | "text-red-500" = 'text-black'
    const quantityDelta = product.quantityTot - product.order.totQuantity
    if (product.quantityInCart !== 0) {
        // e' in ordine
        if (quantityDelta > 0) {
            // e' aumentato
            color = 'text-green-500'
        } else if (quantityDelta < 0) {
            // e' diminuito
            color = 'text-red-500'
        }
    } else {
        // non e' in ordine
        if (product.order.totQuantity === 0) {
            // non era in ordine neanche prima
            color = 'text-black'
        }
    }
    return color
}

const ProductShopInfo = (props: ProductShopInfoProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const product = props.product
    const productQuantity = calculateNumberOfProducts(product.size, product.quantityTot, product.orderType || ProductOrderTypes.unknown)
    return <div key={product._id}>
            <div className="flex justify-between uppercase">
                <div className="flex">
                    {
                        (envMode)? <div onClick={() => setIsOpen(!isOpen)}>
                            {(isOpen)?
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" /></svg>:
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" /></svg>                        
                            }
                        </div>: null
                    }
                    <div className={productColor(product) + ' flex'}>
                    {productQuantity !== Infinity? productQuantity:""} {product.name} {(props.notPresentInApp)? 
                        <div className='pl-1 text-orange-500' onClick={() => props.openModal()}>
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                        </div>:''}
                    </div>
                </div>
                <div>
                    {transformProductOrderTypes(product.orderType,product.quantityTot)}
                </div>
            </div>
                <div className={(isOpen === false)? "pl-8 hidden": "pl-8"}>
                <div>
                    ordine ricorrente: {transformProductOrderTypes(product.orderType,product.order.normalQuantity)}
                </div>
                <div>
                    solamente domani: {transformProductOrderTypes(product.orderType,product.order.exceptionQuantity)}
                </div>
                <hr />
                <div>
                    totale: {transformProductOrderTypes(product.orderType,product.order.totQuantity)}
                </div>
                <div>
                    modifica: {transformProductOrderTypes(product.orderType,product.quantityInCart)}
                </div>
                <hr />
                <div>
                    totale: {transformProductOrderTypes(product.orderType,product.quantityTot)}
                </div>
            </div>
        </div>
}

interface ShopCartViewProps {
    route?: any;
  }


const productsToShow = (products: LocalProduct[]): LocalProduct[] => {
    const resProducts: LocalProduct[] = []
    for (const product of products) {
        if (product.quantityInCart !== 0) {
            resProducts.push(product)
        } else {
            if (product.order.totQuantity > 0) {
                resProducts.push(product)
            }
        }
    }
    return resProducts
}

export const ShopCartView = (props: ShopCartViewProps) => {
    const shop = useSelector(selectShop);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    // console.log(shop.products)
    // console.log('orderDay', shop.orderDay)
    let orderDay = new Date(shop.orderDay)
    // const productsInOrdering = shop.products.filter((product) => (product && product.quantityTot && product.quantityTot>0))
    const productsInOrdering = productsToShow(shop.products)
    const productsinCart = shop.products.filter((product) => (product && product.quantityInCart && product.quantityInCart>0))
    const [isModelOpen, setIsModalOpen] = useState(false)
    const [orderType, setOrderType] = useState<{type: string, days:number[]}>({type: canOrderFrom(orderDay)? 'fromTomorrow': 'justTomorrow', days:[]});
    const [note, setNote] = useState<string>('')

    const [sendNewOrder, { error: newOrderError, loading: newOrderLoading, data: newOrderData }] = useMutation<
        newOrderData,
        newOrderVariables
    >(NEW_ORDER,{
        onCompleted: (data) => {
            if (data.UserNewOrder.success=== true) {
                for (let i = 0; i < productsInOrdering.length; i++) {
                    const element = productsInOrdering[i];
                    dispatch(resetCartProductQuantity(element))
                }
                navigate('/')
            } else {
                console.log(`Error in new order: ${data.UserNewOrder.error}`)
            }
        }
    });

    const [sendNewOrderDaysOfTheWeek, { error: newOrderDaysOfTheWeekError, loading: newOrderDaysOfTheWeekLoading, data: newOrderDaysOfTheWeekData }] = useMutation<
        newOrderDaysOfTheWeekData,
        newOrderDaysOfTheWeekVariables
    >(NEW_ORDER_DAYS_OF_THE_WEEK,{
        onCompleted: (data) => {
            if (data.UserNewOrderDaysOfTheWeek.success=== true) {
                for (let i = 0; i < productsInOrdering.length; i++) {
                    const element = productsInOrdering[i];
                    dispatch(resetCartProductQuantity(element))
                }
                navigate('/')
            } else {
                console.log(`Error in new order: ${data.UserNewOrderDaysOfTheWeek.error}`)
            }
        }
    });

    const [sendNewOrderJustOneDay, { error: newOrderJustOneDayError, loading: newOrderJustOneDayLoading, data: newOrderJustOneDayData }] = useMutation<
        newOrderJustOneDayData,
        newOrderJustOneDayVariables
    >(NEW_ORDER_JUST_ONE_DAY,{
        onCompleted: (data) => {
            if (data.UserNewOrderJustOneDay.success=== true) {
                for (let i = 0; i < productsInOrdering.length; i++) {
                    const element = productsInOrdering[i];
                    dispatch(resetCartProductQuantity(element))
                }
                navigate('/')
            } else {
                console.log(`Error in new order: ${data.UserNewOrderJustOneDay.error}`)
            }
        }
    });

    if (newOrderLoading) {
        // console.log('newOrderLoading', newOrderLoading);
        return <div className="flex flex-col">
        <div className="text-center text-2xl mt-24 mb-8">
          Invio Ordine in corso
        </div>
        <div className="flex justify-center">
          <img className="rounded-xl h-48" src="/images/windmill.gif" alt="Loading" />
        </div>
        <div className="text-center text-2xl mt-8">
          Attendere..
        </div>
      </div>
    }
    
    if (newOrderJustOneDayLoading) {
        // console.log('newOrderJustOneDayLoading', newOrderJustOneDayLoading);
        return <div className="flex flex-col">
        <div className="text-center text-2xl mt-24 mb-8">
          Invio Ordine in corso
        </div>
        <div className="flex justify-center">
          <img className="rounded-xl h-48" src="/images/windmill.gif" alt="Loading" />
        </div>
        <div className="text-center text-2xl mt-8">
          Attendere..
        </div>
      </div>
    }
    
    if (newOrderDaysOfTheWeekLoading) {
        // console.log('newOrderDaysOfTheWeekLoading', newOrderDaysOfTheWeekLoading);
        return <div className="flex flex-col">
        <div className="text-center text-2xl mt-24 mb-8">
          Invio Ordine in corso
        </div>
        <div className="flex justify-center">
          <img className="rounded-xl h-48" src="/images/windmill.gif" alt="Loading" />
        </div>
        <div className="text-center text-2xl mt-8">
          Attendere..
        </div>
      </div>
    }

    if (newOrderError) {
        console.log('newOrderError', newOrderError);
    }

    if (newOrderData) {
        console.log('newOrderData', newOrderData);
    }

    const handleNewOrder = () => {
        const order = {
            note,
            products: [...productsInOrdering, ...shop.notPresentsProducts].map((product) => ({
                id: product.externalId,
                quantity: product.quantityTot
            }))
        }
        if (orderType.type === "fromTomorrow") {
            // per come e' strutturato il gestionale posso inviare un ordine solamente partendo da domani (o oggi se e' prima dell'1 di notte)
            sendNewOrder({ variables: { order, date: getJustTomorrow().date} });
        }
        if (orderType.type === "justTomorrow") {
            sendNewOrderJustOneDay({ variables: { order, date: new Date(shop.orderDay) } });
        }
        if (orderType.type === "someDays") {
            sendNewOrderDaysOfTheWeek({ variables: { order, days: orderType.days } });
        }
        // console.log(order)
        // console.log(productsinCart)
        // console.log(shop)
        // for (const product of shop.products) {
        //     console.log(product.name)
        //     console.log(`era ${product.order.totQuantity}`)
        //     console.log(`in carrello ${product.quantityInCart}`)
        //     console.log(`divanta ${product.quantityTot}`)
        //     const quantityDelta = product.quantityTot - product.order.totQuantity
        //     console.log(`delta ${quantityDelta}`)
        //     let color = 'black'
        //     if (product.quantityInCart !== 0) {
        //         // e' in ordine
        //         if (quantityDelta > 0) {
        //             // e' aumentato
        //             color = 'green'
        //         } else if (quantityDelta < 0) {
        //             // e' diminuito
        //             color = 'red'
        //         }
        //     } else {
        //         // non e' in ordine
        //         if (product.order.totQuantity === 0) {
        //             // non era in ordine neanche prima
        //             color = ''
        //         }
        //     }
        //     console.log(`color ${color}`)
        // }
    }

    const handleResetOrder = () => {
        console.log('Reset Order')
        for (let i = 0; i < productsInOrdering.length; i++) {
            const element = productsInOrdering[i];
            dispatch(resetProductQuantity(element))
        }
    }
    function closeModal() {
        setIsModalOpen(false)
    }

    function openModal() {
        setIsModalOpen(true)
    }

    function handleNoteChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        // console.log(e.target.value)
        setNote(e.target.value)
    }

    return(
    <>
        <Modal
              handleShowModal={setIsModalOpen}
              title="Info Prodotto"
              showModal={isModelOpen}
              body={<div className="">
              <div className='flex justify-end'>
                <div className='w-full text-center text-2xl'>Prodotto non presente in app</div>
              </div>
              <div className='grid grid-cols-1 justify-items-center pt-2 text-center'>
                <div>
                I prodotti contrassegnati con l'icona
                </div>
                <div className='pl-1 text-orange-500'>
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                </div> 
                <div>
                non sono attualmente gestibili in app
                </div>
                <div className='pt-2'>
                  Non è quindi possibile diminuire o aumentare la quantità di tale prodotto
                </div>
                <div className='pt-2'>
                  Per modificarne la quantità contattare Pandoper
                </div>
              </div>
            </div>

              }
          />
        <div className="grid grid-cols-1 md:grid-cols-2 h-full">
            <div className="flex flex-1 flex-col px-2 overflow-auto">
                <div className="text-center font-bold pb-2 text-2xl"><div>
                    Ordine
                </div>
                <TextArea 
                    id="note"
                    value={note}
                    onChange={handleNoteChange}
                    type="text" 
                    required={false} 
                    placeholder="Note per l'ordine"
                    />
                <Button full color="white" size="xs" onClick={handleResetOrder} >Svuota Carrello</Button></div>
                <div className="h-screen overflow-auto text-center">
                    {(productsInOrdering?.length === 0 && shop.notPresentsProducts?.length === 0)? 'Nessun prodotto presente': null}
                    {
                        (productsInOrdering.length > 0)?
                            productsInOrdering.map((product) => (
                                <ProductShopInfo openModal={openModal} key={product._id} product={product} />
                            )): ''
                    }
                    {
                        (shop.notPresentsProducts.length > 0)?
                        shop.notPresentsProducts.map((product) => (
                                <ProductShopInfo openModal={openModal} key={product._id} product={product} notPresentInApp={true}/>
                            )): ''
                    }
                </div>
                <div className="hidden md:grid justify-items-center fixed bottom-2 w-full px-2">
                    <Button className="max-w-md px-2" full color="green" size="lg" onClick={handleNewOrder} >Concludi Ordine</Button>
                </div>
            </div>
            <div className="flex flex-1 flex-col w-full ">
                <OrderTypeSelect onChange={setOrderType} choice={orderType}/>
                <div className="hidden md:flex justify-center items-center h-full">
                    <OrderTypeCalendar choice={orderType} />
                </div>
                <div className="p-2 md:hidden space-y-2">
                    <Button full color="green" size="lg" onClick={handleNewOrder} >Concludi Ordine</Button>
                </div>
            </div>
        </div>
    </>
    )
  }