// views
import {  useQuery } from "@apollo/client";
import { useEffect } from "react";
import {   Route, Routes, useNavigate } from "react-router-dom";
import { TopNavbar } from "../components/Navbar/TopNavBar";
import {  TAuthUser } from "../storage";
import { USER_LOGGED } from "../storage/queries/userState";
import { DashView } from "../views/home/DashView";
import {  InitAppRefreshShopFromCloud  } from "./InitApp";
import { LOG } from "../config";
import { Loading } from "../ui";
import { UserView } from "../views/home/User";
import { AllProductsListView } from "../views/products/AllProductsListView";
import { ShopCartView } from "../views/shopCart/ShopCartView";
import { InitTutorial } from "./InitTutorial";

interface AdminProps { }

const log = LOG.extend('Layouts')

const Admin = (props: AdminProps) => {
  log.debug('Admin Layout')

  let navigate = useNavigate();
  const { data, loading, error } = useQuery(USER_LOGGED) as unknown as {data: {userLogged: TAuthUser}, loading: boolean, error: string}

  useEffect(() => {
    if (!data?.userLogged) {
      log.debug('User is not logged, redirecting to /auth')
      navigate('/auth')
    }
  },[data])

  if (loading) {
    log.debug('Admin Layout USER_LOGGED loading')
    return <div className="grid place-content-center h-full">
      <Loading />
      <div>
          Caricamento Utente
      </div>
    </div>
    }

  if (error) {
    log.debug(`Admin Layout USER_LOGGED error ${error}`)
    return (<div>{error}</div>)
  }

  return (
    <div>
      <div className=''>
          <InitAppRefreshShopFromCloud>
            <InitTutorial >
              <div
                className="min-h-screen h-screen"
              >
                <TopNavbar />
                <div className="h-full pt-16">
                  <Routes>
                    <Route path="user" element={<UserView />} />
                    <Route path="shop" element={<ShopCartView />} />
                    <Route path="products/all/*" element={<AllProductsListView />} />
                    <Route path="*" element={<DashView />} />
                  </Routes>
                </div>
              </div>
            </InitTutorial>
          </InitAppRefreshShopFromCloud>
      </div>
      {/* <div className='hidden sm:block'>
        <InitAppRefreshShopFromCloud>
          <InitTutorial >
            <div
              className="min-h-screen h-screen"
            >
              <TopNavbar />
              <div className="h-full pt-16">
                <Routes>
                  <Route path="user" element={<UserView />} />
                  <Route path="shop" element={<ShopCartView />} />
                  <Route path="products/all/*" element={<AllProductsListView />} />
                  <Route path="*" element={<DashView />} />
                </Routes>
              </div>
            </div>
          </InitTutorial>
        </InitAppRefreshShopFromCloud>
      </div> */}
    </div>
  );
};

export { Admin };
