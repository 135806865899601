import { Link, useLocation } from "react-router-dom"

const routes = [{
        path: '/', 
        name: 'home',  
        title: 'Pandop',
        subTitle: '0461 58 59 21'
    },
  ];

export const PrintRoute = () => {
    const pathName = useLocation().pathname;
    const route = routes.find((route) => route.path === pathName)
    return <div className="pt-6 text-center">
        <div className="text-base">{(route)? route.title : routes[0].title}</div>
        <div className="text-xs">{(route)? route.subTitle : routes[0].subTitle}</div>
    </div>
}

export const PrintNavButtons = () => {
    const pathName = useLocation().pathname;
    
    return <>
        <Link to="/">
            {(pathName === '/')?
              <img className="rounded-full h-12 object-cover w-12" src='/images/home-icon-64.png' alt='Frutta' />:
              <img className="rounded-full h-12 object-cover w-12 brightness-50" src='/images/home-icon-64.png' alt='Frutta' />
            }
        </Link>
    </>
}