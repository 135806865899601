import { Button, Loading } from "../../ui"
import { logout } from "../../libs/auth";
import { useQuery } from "@apollo/client";
import { USER_LOGGED } from "../../storage/queries/userState";
import { TAuthUser } from "../../storage";
import { User, USER_ME } from "../../storage/queries/user";
import { transformProductOrderTypes } from "../../libs/toolbox";
import pack from "../../../package.json";
import { useDispatch } from "react-redux";
import { enableTutorial } from "../../storage/redux/shopSlice";

interface UserViewProps {

}

export const UserView = (props: UserViewProps) => {
    const dispatch = useDispatch();
    const { data, loading, error } = useQuery(USER_ME,{fetchPolicy: "network-only"}) as unknown as {data: {UserMe: User}, loading: boolean, error: string}

    if (loading) return <div className="grid place-content-center h-full">
        <Loading />
        <div>
            Caricamento Info Utente
        </div>
    </div>
    if (error) return <div className="grid content-center text-center">Errore in caricamento Info utente, verificare connessione internet</div>
    if (!data) return <div>No data</div>

    const infoVarie = <div>
        <div>Info varie: </div>
    </div>

    const asyncLogout = async () => {
        await logout();
        window.location.href = "/";
    }

    return <div className="flex flex-col space-y-4 pt-4 justify-center items-center ">
        <div className="text-center text-3xl">{data.UserMe.basicInfo.name}</div>
        <div className="text-center text-2xl">{data.UserMe.email}</div>
        <div className="flex flex-col space-y-2 px-2">
            <Button size="lg" color="white" disabled={false} onClick={() => {
                dispatch(enableTutorial(true))
            }}>Tutorial</Button>
            <Button size="lg" color="white" disabled={false} onClick={asyncLogout}>Logout</Button>
        </div>
        <div className="fixed bottom-2 w-full">
            <div className="text-center text-gray-800  mx-2 rounded-md py-2">
               Powered by Onubo s.r.l. - versione<Button color="transparent" onClick={()=>window.location.reload()}>{pack.version}</Button> 
            </div>
        </div>
        <div className="h-12"></div> 
    </div>
}