import { ReactNode, useState } from "react";
import { LocalProduct, Product } from "../../storage/types";
import { ProductCard } from "./Product";
import { LOG } from "../../config";
import { Modal } from "../../ui/Modal";
import { transformDeltaFromMidnightToString } from "../../libs/toolbox";
import { CardSlider } from "./CardSlider";

const log = LOG.extend('ProductList')

interface ProductListProps {
    children?: ReactNode;
    products: LocalProduct[]
}

export const ProductList = (props: ProductListProps) => {
    const [infoProduct, setInfoProduct] = useState<LocalProduct | null>(null)
    const productsDesktop : JSX.Element[]= []
    const productsMobile : JSX.Element[]= []
    for (const product of props.products) {
        productsMobile.push(<ProductCard key={product._id} product={product} onInfo={(product) => setInfoProduct(product)} isForDesktop={false}/>)
        productsDesktop.push(<ProductCard key={product._id} product={product} onInfo={(product) => setInfoProduct(product)} isForDesktop={true}/>)
    }
    const sponsoredProducts = props.products.filter((product) => product.ifSponsored)


    const closeModal = () => {
        setInfoProduct(null)
    }
    const imageUrl = infoProduct?.image?.url || "/images/icon-192.png"
    return <div>
            <Modal
                showModal={infoProduct !== null}
                handleShowModal={closeModal}
                title={infoProduct?.name || 'Prodotto'}
                body={<div className="select-none ">
                    <div className="flex pt-2 justify-center max-h-32">
                        {/* imagine */}
                        <img className="h-auto w-auto rounded-lg object-cover" src={imageUrl} alt={infoProduct?.name} />
                    </div>
                    <div>
                        Grammatura: {infoProduct?.size} gr.
                    </div>
                    {(infoProduct?.externalInfo?.ingredients)? 
                        <div>
                            Ingredienti: {infoProduct?.ingredients}
                        </div>: null
                    }
                    {(infoProduct?.deliverability)?
                        <div>
                            Ordinabile fino {transformDeltaFromMidnightToString(infoProduct?.deliverability)}
                        </div> : 'Ordinabile fino a mezzanotte'
                    }
                </div>

                }
            />
            <div className="space-y-2 sm:hidden">
                <CardSlider products={sponsoredProducts} onInfo={(product) => setInfoProduct(product)} isForDesktop={false}/>
                {productsMobile}
            </div>
            <div className="hidden sm:grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 xl:max-w-screen-xl gap-2 m-auto">
                <CardSlider products={sponsoredProducts} onInfo={(product) => setInfoProduct(product)} isForDesktop={true}/>
                {productsDesktop}
            </div>
        </div>
}