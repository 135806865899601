import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { LOG } from "../../config"
import { selectShop } from "../../storage/redux/shopSlice"

const log = LOG.extend('unknown')

export const ShoppingCart = () => {
    log.debug('ShoppingCart')
    const shop = useSelector(selectShop);
    const pathName = useLocation().pathname;
  
    const productsinCart = shop.products.filter((product) => (product && product.quantityInCart && product.quantityInCart>0))

    const Home = <Link className='' to='/'>
        <div className="flex flex-col">
            <div>
                <img className="h-12 rounded-full" src='/images/home-icon-64.png' alt='Home' />
            </div>
        </div>
    </Link>

    const Shop = <Link className='' to='/shop'>
    <div className="flex flex-col">
        {productsinCart.length > 0 ? <div className="rounded-full bg-red-500 h-2 w-2">
            
        </div> : null
        }
        <div className="">
            <img className={productsinCart.length > 0 ? "h-8" : "h-12"} src="/images/shoppingCart-64.png" alt="Carrello" />
        </div>
    </div>
    </Link>

    const Products = <Link className='' to='/products/all'>
        <div className="flex flex-col">
            <div>
                <img className="h-8 rounded-full" src='/images/bread_icon.png' alt='Pane' />
            </div>
        </div>
    </Link>

    switch (pathName) {
        case '/shop':
            return Products
        default:
            return Shop
    }
}