import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CONFIG } from '../../config';
import { ShoppingCart } from '../User/ShoppingCart';
import { UserMenu } from '../User/UserMenu';
import { PrintRoute } from './printRoute';

export const TopNavbar = () => {

  const bgColor = 'bg-white'


  return (
    <>
      <nav
        style={{ zIndex: 999 }}
        className={`fixed top-0 left-0 w-full z-10 ${bgColor} flex flex-row py-4 px-2 justify-between h-16 items-center`}
      >
        <div className=''>
          <UserMenu />
        </div>
        <div>
          <PrintRoute />
          <div className='text-sm text-orange-500'>
          {(CONFIG.mode === 'development' || CONFIG.mode === 'staging')? CONFIG.mode : ''}
          </div>
        </div>
        <div className=''>
          <ShoppingCart />
        </div>
      </nav>
    </>
  );
};

