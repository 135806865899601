import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LOG } from '../../config'
import { LocalProduct, Product, ProductOrderTypes, UserOrder } from '../types'
import type { RootState } from './store'
import hash from 'object-hash'
import { appStorage } from '..'
import { canOrderFrom, round2Decimals } from '../../libs/toolbox'

const log = LOG.extend('Reducers')
const orderDay = new Date()
if (!canOrderFrom(new Date())) {
  orderDay.setDate(orderDay.getDate() + 1)
}
orderDay.setHours(0,0,0,0);

// Define a type for the slice state
interface ShopState {
  selectedProduct: LocalProduct | null,
  products: LocalProduct[],
  notPresentsProducts: LocalProduct[],
  orderDay: string,
  dbVersion?: string,
  lastUpdate?: number,
  showTutorial?: Boolean,
  preferredProducts?: string[]
}

// Define the initial state using that type
const initialState: ShopState = {
  selectedProduct: null,
  products: [],
  notPresentsProducts: [],
  orderDay: orderDay.toString(),
}

export const shopSlice = createSlice({
  name: 'shop',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    selectProduct: (state, action: PayloadAction<LocalProduct | null>) => {
      state.selectedProduct = action.payload
      appStorage.setObject('shop',{
        ...state,
      });
    },
    enableTutorial: (state, action: PayloadAction<Boolean>) => {
      state.showTutorial = action.payload
      appStorage.setObject('shop',{
        ...state,
      });
    },
    changeOrderDay: (state, action: PayloadAction<string>) => {
      state.orderDay = action.payload
      appStorage.setObject('shop',{
        ...state,
      });
    },
    initProductsFromCache: (state, action: PayloadAction<{products: LocalProduct[], dbVersion: string, isOnline:boolean, showTutorial: boolean, preferredProducts: string[]}>) => {
      log.debug('Get Products from Cache')
      state.products = action.payload.products
      state.dbVersion = action.payload.dbVersion
      state.lastUpdate = new Date().getTime()
      state.showTutorial = action.payload.showTutorial
      state.preferredProducts = action.payload.preferredProducts
      if (action.payload.isOnline) {
        appStorage.setObject('shop',{
          ...state
        });
      }
      appStorage.setObject('shop',{
        ...state
      });
    },
    initProducts: (state, action: PayloadAction<{products: LocalProduct[], dbVersion: string, isOnline:boolean, showTutorial: boolean, preferredProducts: string[]}>) => {
      log.debug('initProducts')
      state.products = action.payload.products
      state.dbVersion = action.payload.dbVersion
      state.lastUpdate = new Date().getTime()
      state.showTutorial = action.payload.showTutorial
      state.preferredProducts = action.payload.preferredProducts
      if (action.payload.isOnline) {
        appStorage.setObject('shop',{
          ...state
        });
      }
    },
    resetProductsQuantity: (state) => {
      state.products = state.products.map((product) => {
        product.quantityInCart = 0
        return product
      })
      appStorage.setObject('shop',{
        ...state
      });
    },
    setShopTomorrowOrder: (state, action: PayloadAction<{order: UserOrder}>) => {
      log.debug('setTomorrowOrder in Shop')
      state.notPresentsProducts = []
      for (let i = 0; i < state.products.length; i++) {
        state.products[i].quantityInCart = 0
        state.products[i].quantityTot = 0
        state.products[i].order.normalQuantity = 0
        state.products[i].order.exceptionQuantity = 0
        state.products[i].order.totQuantity = 0
      }
      // loop order
      for (let i = 0; i < action.payload.order.products.length; i++) {
        const element = action.payload.order.products[i];
        const productIndex = state.products.findIndex((product) => product._id.toString() === element.product._id.toString())
        if (productIndex !== -1) {
          state.products[productIndex].order.normalQuantity = element.quantity
          state.products[productIndex].order.totQuantity = state.products[productIndex].order.normalQuantity
          state.products[productIndex].quantityInCart = 0
          state.products[productIndex].quantityTot =  state.products[productIndex].order.totQuantity
        } else {
          // check if already present in notPresentsProducts
          log.error(`Product  ${element.product._id} not found in shop for product`)
          const notPresentsProductIndex = state.notPresentsProducts.findIndex((product) => product._id.toString() === element.product._id.toString())
          if (notPresentsProductIndex === -1) {
            state.notPresentsProducts.push({
              ...element.product,
              order: {
                normalQuantity: element.quantity,
                totQuantity: element.quantity,
                exceptionQuantity: 0,
              },
              quantityInCart: 0,
              quantityTot:  element.quantity
            })
          } else {
            state.notPresentsProducts[notPresentsProductIndex].order.normalQuantity = element.quantity
            state.notPresentsProducts[notPresentsProductIndex].order.totQuantity = element.quantity
            state.notPresentsProducts[notPresentsProductIndex].order.exceptionQuantity = 0
            state.notPresentsProducts[notPresentsProductIndex].quantityInCart = 0
            state.notPresentsProducts[notPresentsProductIndex].quantityTot =  element.quantity
          }
        }
      }
      // loop exception
      for (let i = 0; i < action.payload.order.exceptionsProducts.length; i++) {
        const element = action.payload.order.exceptionsProducts[i];
        const productIndex = state.products.findIndex((product) => product._id.toString() === element.product._id.toString())
        if (productIndex !== -1) {
          state.products[productIndex].order.exceptionQuantity = element.quantity
          state.products[productIndex].order.totQuantity = state.products[productIndex].order.exceptionQuantity + state.products[productIndex].order.normalQuantity
          state.products[productIndex].quantityInCart = 0
          state.products[productIndex].quantityTot = state.products[productIndex].order.totQuantity
        } else {
          // check if already present in notPresentsProducts
          log.error(`Product  ${element.product._id} not found in shop for exception`)
          const notPresentsProductIndex = state.notPresentsProducts.findIndex((product) => product._id.toString() === element.product._id.toString())
          if (notPresentsProductIndex === -1) {
            state.notPresentsProducts.push({
              ...element.product,
              order: {
                normalQuantity: 0,
                totQuantity: element.quantity,
                exceptionQuantity: element.quantity,
              },
              quantityInCart: 0,
              quantityTot:  element.quantity
            })
          } else {
            state.notPresentsProducts[notPresentsProductIndex].order.totQuantity = state.notPresentsProducts[notPresentsProductIndex].order.totQuantity + element.quantity
            state.notPresentsProducts[notPresentsProductIndex].order.exceptionQuantity = element.quantity
            state.notPresentsProducts[notPresentsProductIndex].quantityInCart = 0
            state.notPresentsProducts[notPresentsProductIndex].quantityTot =  state.notPresentsProducts[notPresentsProductIndex].order.totQuantity
          }
        }
      }
      appStorage.setObject('shop',{
        ...state
      });
    }, 
    increaseDecimalProductQuantity: (state, action: PayloadAction<Product>) => {
      log.debug(`Increasing product ${action.payload.name} quantity`)
      log.debug(`Finding the product`)
      const productIndex = state.products.findIndex((product) => product._id.toString() === action.payload._id.toString())
      if (productIndex === -1) {
        log.error(`Product NOT present in shop`)
      } else {
        const product = state.products[productIndex]
        if (product.orderType === ProductOrderTypes.kg) {
          log.debug(`Product found in the shop, i will increment the quantity in kg`)
          state.products[productIndex].quantityInCart = round2Decimals(state.products[productIndex].quantityInCart + state.products[productIndex].size / 1000)
          state.products[productIndex].quantityTot = round2Decimals(state.products[productIndex].quantityInCart + state.products[productIndex].order.totQuantity)
        } else {
          log.debug(`Product found in the shop, i will increment the quantity in kg`)
          state.products[productIndex].quantityInCart = Math.round(state.products[productIndex].quantityInCart + 1)
          state.products[productIndex].quantityTot = Math.round(state.products[productIndex].quantityInCart + state.products[productIndex].order.totQuantity)
        }
      }
      appStorage.setObject('shop',{
        ...state
      });
    },
    decreaseDecimalProductQuantity: (state, action: PayloadAction<Product>) => {
      log.debug(`Decreasing product ${action.payload.name} quantity`)
      log.debug(`Finding the product`)
      const productIndex = state.products.findIndex((product) => product._id.toString() === action.payload._id.toString())
      if (productIndex === -1) {
        log.error(`Product NOT present in the shop`)
      } else {
        const product = state.products[productIndex]
        log.debug(`Product found in the shop, i will decrease the quantity`)
        if (state.products[productIndex].quantityTot > 0) {
          if (product.orderType === ProductOrderTypes.kg) {
            state.products[productIndex].quantityInCart = round2Decimals(state.products[productIndex].quantityInCart - state.products[productIndex].size / 1000)
            state.products[productIndex].quantityTot = round2Decimals(state.products[productIndex].quantityTot - state.products[productIndex].size / 1000)
          } else {
            state.products[productIndex].quantityInCart = Math.round(state.products[productIndex].quantityInCart - 1)
            state.products[productIndex].quantityTot = Math.round(state.products[productIndex].quantityTot - 1)
          }
        } else {
          state.products[productIndex].quantityTot = 0
        }
      }
      appStorage.setObject('shop',{
        ...state
      });
    },
    increaseProductQuantity: (state, action: PayloadAction<Product>) => {
      log.debug(`Increasing product ${action.payload.name} quantity`)
      log.debug(`Finding the product`)
      const productIndex = state.products.findIndex((product) => product._id.toString() === action.payload._id.toString())
      if (productIndex === -1) {
        log.error(`Product NOT present in shop`)
      } else {
        const product = state.products[productIndex]
        log.debug(`Product found in the shop, i will increment the quantity`)
        if (product.orderType === ProductOrderTypes.kg) {
          state.products[productIndex].quantityInCart = round2Decimals(state.products[productIndex].quantityInCart + (state.products[productIndex].size / 1000)*5)
          state.products[productIndex].quantityTot = round2Decimals(state.products[productIndex].quantityInCart + state.products[productIndex].order.totQuantity)
        } else {
          state.products[productIndex].quantityInCart = Math.round(state.products[productIndex].quantityInCart) + 5
          state.products[productIndex].quantityTot = Math.round(state.products[productIndex].quantityInCart) + state.products[productIndex].order.totQuantity
        }
      }
      appStorage.setObject('shop',{
        ...state
      });
    },
    decreaseProductQuantity: (state, action: PayloadAction<Product>) => {
      log.debug(`Decreasing product ${action.payload.name} quantity`)
      log.debug(`Finding the product`)
      const productIndex = state.products.findIndex((product) => product._id.toString() === action.payload._id.toString())
      if (productIndex === -1) {
        log.error(`Product NOT present in the shop`)
      } else {
        const product = state.products[productIndex]
        log.debug(`Product found in the shop, i will decrease the quantity`)
        let quantityTot = state.products[productIndex].quantityTot
        let quantityInCart = state.products[productIndex].quantityInCart
        if (product.orderType === ProductOrderTypes.kg) {
          if (quantityTot > (state.products[productIndex].size / 1000)*5) { 
            quantityTot = round2Decimals(quantityTot - (state.products[productIndex].size / 1000)*5)
            quantityInCart = round2Decimals(quantityInCart - (state.products[productIndex].size / 1000)*5)
          } else {
            quantityInCart = quantityInCart - quantityTot
            quantityTot = 0
          }
        } else {
          if (quantityTot > 5) {
            quantityTot = Math.round(quantityTot - 5)
            quantityInCart = Math.round(quantityInCart - 5)
          } else {
            quantityInCart = quantityInCart - quantityTot
            quantityTot = 0
          }
        }
        state.products[productIndex].quantityInCart = quantityInCart
        state.products[productIndex].quantityTot = quantityTot
      }
      appStorage.setObject('shop',{
        ...state
      });
    },
    resetCartProductQuantity: (state, action: PayloadAction<Product>) => {
      log.debug(`Resetting cart product ${action.payload.name} quantity`)
      log.debug(`Finding the product`)
      const productIndex = state.products.findIndex((product) => product._id.toString() === action.payload._id.toString())
      if (productIndex === -1) {
        log.error(`Product NOT present in shop`)
      } else {
        log.debug(`Product found in the shop, i will reset the quantity`)
        state.products[productIndex].quantityInCart = 0
      }
      appStorage.setObject('shop',{
        ...state
      });
    },
    resetProductQuantity: (state, action: PayloadAction<Product>) => {
      log.debug(`Resetting product ${action.payload.name} quantity`)
      log.debug(`Finding the product`)
      const productIndex = state.products.findIndex((product) => product._id.toString() === action.payload._id.toString())
      if (productIndex === -1) {
        log.error(`Product NOT present in shop`)
      } else {
        log.debug(`Product found in the shop, i will reset the quantity`)
        state.products[productIndex].quantityInCart = 0
        state.products[productIndex].quantityTot = 0
      }
      appStorage.setObject('shop',{
        ...state
      });
    },
    addPreferredProduct: (state, action: PayloadAction<string>) => {
      log.debug(`Adding preferred product ${action.payload}`)
      log.debug(`Finding the product`)
      // check if preferredProducts is present
      if (!state.preferredProducts) {
        state.preferredProducts = []
      }
      const productIndex = state.preferredProducts.findIndex((product) => product === action.payload)
      if (productIndex === -1) {
        log.debug(`Product NOT present in preferredProducts`)
        state.preferredProducts.push(action.payload)
      } else {
        log.debug(`Product already present in preferredProducts`)
      }
      appStorage.setObject('shop',{
        ...state
      });
    },
    removePreferredProduct: (state, action: PayloadAction<string>) => {
      log.debug(`Removing preferred product ${action.payload}`)
      log.debug(`Finding the product`)
      // check if preferredProducts is present
      if (!state.preferredProducts) {
        state.preferredProducts = []
      }
      const productIndex = state.preferredProducts.findIndex((product) => product === action.payload)
      if (productIndex !== -1) {
        log.debug(`Product present in preferredProducts`)
        state.preferredProducts.splice(productIndex, 1)
      } else {
        log.debug(`Product NOT present in preferredProducts`)
      }
      appStorage.setObject('shop',{
        ...state
      });
    }
  },
})

export const {changeOrderDay, enableTutorial, selectProduct, resetCartProductQuantity, resetProductQuantity, increaseProductQuantity, decreaseProductQuantity, increaseDecimalProductQuantity, decreaseDecimalProductQuantity, initProducts, resetProductsQuantity, initProductsFromCache, setShopTomorrowOrder, addPreferredProduct, removePreferredProduct } = shopSlice.actions

export const selectShop = (state: RootState) => state.shop

export const shopReducer = shopSlice.reducer