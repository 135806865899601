import { LOG } from "../config";
import { LocalProduct, Product } from "./types";

const DB = localStorage;
const appKey = (key: string) => key;

const log = LOG.extend('Unknown')

export type TAuthUser = {
  accessToken: string
  refreshToken: string
  user: {
    _id: string
  }
}

export type TAppState = {
  products: LocalProduct[],
  originalProducts: Product[],
  lastUpdate: number,
  dbVersion: string,
  showTutorial: boolean,
  preferredProducts: string[],
}

export const appStorage = {
    store: DB,
    getObject: (key: string) => {
      let result = null;
      try {
        let item: any = DB.getItem(key);
        result = JSON.parse(item);
      } catch (error) {
        log.error('appStorage getObject ' + appKey(key) + ' | ' + error);
      }
      return result;
    },
    setObject: (key: string, item: Object) => {
      let result = null;
      try {
        result = result = DB.setItem(appKey(key), JSON.stringify(item));
      } catch (error) {
        log.error('appStorage getObject ' + appKey(key) + ' | ' + error);
      }
      return result;
    },
    removeObject: (key: string) => {
      let result = DB.removeItem(appKey(key));
      return result;
    },
  };
  