import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { LOG } from "../../config"
import { selectShop } from "../../storage/redux/shopSlice"

const log = LOG.extend('UserMenu')

export const UserMenu = () => {
    log.debug('UserMenu')
    const pathName = useLocation().pathname;
  
    const UserProfile = <Link className='' to='/user'>
            <img className='h-14' src='/images/user-192.png' alt='icon' />
    </Link>

  const Home = <Link className='' to='/'>
    <div className="flex flex-col">
        <div>
            <img className="h-12 rounded-full" src='/images/home-icon-64.png' alt='Home' />
        </div>
    </div>
  </Link>

    console.log(pathName)
    switch (pathName) {
      case '/user':
        return Home
      case '/shop':
        return Home
      case '/products/all':
        return Home
      default:
        return UserProfile
    }
}