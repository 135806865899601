import { isSameDay } from "date-fns"
import { LocalProduct, OrderType, ProductOrderTypes, UserOrder } from "../storage/types"

export const translateOrderType = (orderType?: ProductOrderTypes) => {
    switch (orderType) {
        case ProductOrderTypes.kg:
            return 'kg'
        case ProductOrderTypes.piece:
            return 'pz.'
        default:
            return 'unknown'
    }
}

export const transformProductOrderTypes = (productType?: ProductOrderTypes, quantity?: number) => {
    // return `${quantity} kg`
    // cambiato modi di gestire gli ordini, questo non serve piu'
    switch (productType) {
        case ProductOrderTypes.kg:
            return `${quantity} kg`
        case ProductOrderTypes.piece:
            return `${quantity} pz.`
        default:
            return quantity
    }
}

export const calculateProductTotalFromOrder = (order: UserOrder, products: LocalProduct[]): LocalProduct[] => {
    for (let i = 0; i < order.products.length; i++) {
        const element = order.products[i];
        console.log(`check product ${element.product.name}`)
        const index = products.findIndex(p => p._id === element.product._id);
        if (index !== -1) {
            console.log(`changing quantity for product ${element.product.name}`)
            const newQuantity = products[index].quantityTot + element.quantity
            products[index] = {...products[index], quantityTot: newQuantity}
        }
    }
    for (let i = 0; i < order.exceptionsProducts.length; i++) {
        const element = order.exceptionsProducts[i];
        console.log(`check product ${element.product.name}`)
        const index = products.findIndex(p => p._id === element.product._id);
        if (index !== -1) {
            console.log(`changing quantity for product ${element.product.name}`)
            const newQuantity = products[index].quantityTot + element.quantity
            products[index] = {...products[index], quantityTot: newQuantity}
        }
    }
    return products
}

export const round2Decimals = (value: number): number => {
    return Math.round(value * 100) / 100
}

export const calculateNumberOfProducts = (sizeInGr: number, quantity: number, orderType: ProductOrderTypes) => {
    switch (orderType) {
        case ProductOrderTypes.kg:
            return Math.round((quantity*1000) / sizeInGr)
        case ProductOrderTypes.piece:
            return quantity
        default:
            return quantity
    }
}

export const calculateKgOfProduct = (sizeInGr: number, quantity: number, orderType: ProductOrderTypes) => {
    switch (orderType) {
        case ProductOrderTypes.kg:
            return quantity
        case ProductOrderTypes.piece:
            return round2Decimals((quantity*sizeInGr) / 1000)
        default:
            return quantity
    }

}


export const printDate = (date?: Date) => {
    const options : Intl.DateTimeFormatOptions = {
        'weekday': 'long', 
        'month': '2-digit', 
        'day': '2-digit'
    };
    return (date || new Date()).toLocaleString('it-IT', options);
}

export const YYYYMMDDtoDate = (date: string) => {
    const year = date.substring(0,4)
    const month = date.substring(4,6)
    const day = date.substring(6,8)
    return new Date(`${year}/${month}/${day}`)
}

function time_with_leading_zeros(dt: Date) 
{ 
  const minutes = (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
  const hours = dt.getHours()
  return `${hours}:${minutes}`
}

export const transformDeltaFromMidnightToString = (minutes: number) => {
    const deltaDate = new Date()
    deltaDate.setHours(0,0,0,0)
    deltaDate.setMinutes(minutes)
    if (minutes > 0 && minutes < 1440) return `alle ${time_with_leading_zeros(deltaDate)} di notte`
    if (minutes === 0) return 'a mezzanotte'
    if (minutes < 0 && minutes > -1440) return `alle ${time_with_leading_zeros(deltaDate)} del giorno precedente`
    return 'fuori range'
}

// ritorna da oggi mercoledi' 19/10 in poi se prima dell'1 di notte
// oppure da domani giovedi' 20/10 in poi se dopo l'1 di notte
export const getJustTomorrow = () => {
    // limite di ore nella giornata (1 -> fino all'1 di notte)
    const limitHours = 1
    const now = new Date()
    const startOfToday = new Date()
    const tomorrow = new Date()
    const timelimit = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    startOfToday.setHours(24,0,0,0)
    timelimit.setHours(limitHours,0,0,0)
    // se ora e' dopo limitHours allora ritorno domani
    if (now >= timelimit) return {text: `Da domani ${printDate(tomorrow)} in poi`, date: tomorrow}
    return {text: `Da oggi ${printDate(now)} in poi`, date: now}
}

// calcola in base all'ora attuale se puo' essere fatto un ordineDa in base alla data passata
// ritorna false se posso partire da dateFrom, true se devo partire da dateFrom + 1
export const canOrderFrom = (dateFrom: Date) => {
    const now = new Date()
    const tomorrow = new Date()
    const dayLimit = new Date()
    // fino all'una di notte posso ordinare per il giorno stesso
    dayLimit.setHours(1,0,0,0)
    tomorrow.setDate(tomorrow.getDate() + 1)
    if (new Date() > dayLimit) {
        // posso ordinare solemente a partire da domani
        return isSameDay(tomorrow,dateFrom) 
    } else {
        // posso ordinare anche per oggi
        return isSameDay(now, dateFrom)
    }
    
}