import { useState } from "react";
import { canOrderFrom, getJustTomorrow, printDate } from "../../libs/toolbox";
import { useSelector } from "react-redux";
import { selectShop } from "../../storage/redux/shopSlice";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { addDays, compareAsc, differenceInCalendarDays } from "date-fns";
import { Modal } from "../../ui/Modal";

interface OrderTypeSelectProps {
    route?: any;
    choice: {type: string, days:number[]};
    onChange: (value: {type: string, days:number[]}) => void;
  }

export const OrderTypeSelect = (props: OrderTypeSelectProps) => {
    const shop = useSelector(selectShop);
    // const [choice, setChoice] = useState<{type: string, days:number[]}>({type: 'justTomorrow', days: []})
    const [isModelOpen, setIsModalOpen] = useState(false)
    const choice = props.choice 
    console.log('choice', choice)
    const handleChange = (e: any) => {
        if (props.onChange) {
            props.onChange(e.target.value)
        }
    }
    let orderDay = new Date(shop.orderDay)

    function closeModal() {
        setIsModalOpen(false)
    } 

    const updateDays = (day: number) => {
        console.log(`updating days`,props.choice.days, day)
        const days = choice.days
        const dayIndex = days.indexOf(day)
        if (dayIndex === -1) {
            days.push(day)
        } else {
            days.splice(dayIndex,1)
        }
        if (days.length === 0) {
            // se nessun giorno e' selezionato torno alla selezione di solo domani
            handleChange({target:{value: {type: 'justTomorrow', days: []}}})
        } else {
            handleChange({target:{value: {type: 'someDays', days}}})
        }
    }

    return(<>
            <Modal
              showModal={isModelOpen}
              title="Tipo di ordine"
              handleShowModal={setIsModalOpen}
              body={
                <div className="">
                    <div className="pt-1">
                        Qui puoi scegliere quando ricevere l'ordine selezionato
                    </div>
                    <div className="pt-4">
                        <div className="text-center font-bold">
                            Solamente...
                        </div>
                        <div>
                            L'ordine sarà modificato solamente nel giorno riportato
                        </div>
                    </div>
                    <div className="pt-4">
                        <div className="text-center font-bold">
                            Da domani in poi
                        </div>
                        <div>
                            Visibile solamente se il giorno selezionato è domani.
                            L'ordine andrà a sostituire il tuo ordine ricorrente. Riceverai quindi questo ordine tutti i giorni da domani in poi
                        </div>
                    </div>
                    <div className="pt-4">
                        <div className="text-center font-bold">
                            Solamente giorni specifici
                        </div>
                        <div>
                            Selezionando alcuni giorni (all'occorrenza anche tutti), riceverai l'ordine richiesto solamente in quagli specifici giorni
                        </div>
                    </div>
                </div>
              }
            />
            <div className="pt-4">
                <div className="text-center font-bold pb-2 text-2xl">
                    <div className="flex justify-center" onClick={() => {setIsModalOpen(true)}}>
                        Tipo di ordine <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    </div>
                </div>
                <div className="">
                    <div className="text-center">
                        A quando si riferisce questo ordine?
                    </div>
                </div>
                    <div className="pt-2">
                        <div className="grid content-center grid-cols-3">
                            {
                                (canOrderFrom(orderDay))?<>
                                    <div className="grid justify-items-end items-center">
                                        <input className="cursor-pointer" checked={(choice.type === 'fromTomorrow')} onChange={() => handleChange({target:{value: {type: 'fromTomorrow', days:[]}}})} type="checkbox" value="fromTomorrow"/>
                                    </div>
                                    <div onClick={() => handleChange({target:{value: {type: 'fromTomorrow', days:[]}}})} className="pl-2 col-span-2 cursor-pointer">{getJustTomorrow().text}</div>
                                    </> : null
                            }
                            <div className="grid justify-items-end items-center">
                                <input className="cursor-pointer" checked={(choice.type === 'justTomorrow')} onChange={() => handleChange({target:{value: {type: 'justTomorrow', days:[]}}})} type="checkbox" value="justTomorrow"/>
                            </div>
                            <div onClick={() => handleChange({target:{value: {type: 'justTomorrow', days:[]}}})} className="pl-2 col-span-2 cursor-pointer">
                                Solamente {printDate(orderDay)}
                            </div>
                            {/* Se questa opzione e' attiva sempre allora i prodotti che non sarebbero ordinabili possono essere ordinati
                                Es: seleziono dopodomani come data (lo strudel e' ordinabile) e ordino da domani in poi
                            */}
                            
                            <div className="grid justify-items-end items-center">
                                <input className="cursor-pointer" checked={(choice.type === 'someDays')} onChange={() => handleChange({target:{value: {type: 'someDays', days:[orderDay.getDay()]}}})} type="checkbox" value="someDays"/>
                            </div>
                            <div onClick={() => handleChange({target:{value: {type: 'someDays', days:[orderDay.getDay()]}}})} className="pl-2 col-span-2 cursor-pointer">Solamente giorni specifici</div>
                            <div></div>
                            <div className="col-span-2">
                                <div className="grid grid-cols-7 text-center">
                                    <div className="cursor-pointer" onClick={(val) => updateDays(1) }>
                                        L
                                    </div>
                                    <div className="cursor-pointer" onClick={(val) => updateDays(2) }>
                                        M
                                    </div>
                                    <div className="cursor-pointer" onClick={(val) => updateDays(3) }>
                                        M
                                    </div>
                                    <div className="cursor-pointer" onClick={(val) => updateDays(4) }>
                                        G
                                    </div>
                                    <div className="cursor-pointer" onClick={(val) => updateDays(5) }>
                                        V
                                    </div>
                                    <div className="cursor-pointer" onClick={(val) => updateDays(6) }>
                                        S
                                    </div>
                                    <div className="cursor-pointer" onClick={(val) => updateDays(7) }>
                                        D
                                    </div>
                                    <div>
                                        <div className="grid justify-items-center items-center">
                                            <input className="cursor-pointer" checked={(choice.type === 'someDays' && choice.days.includes(1))} onChange={(val) => updateDays(1) } type="checkbox" value="fromTomorrow"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="grid justify-items-center items-center">
                                            <input className="cursor-pointer" checked={(choice.type === 'someDays' && choice.days.includes(2))} onChange={(val) => updateDays(2) } type="checkbox" value="fromTomorrow"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="grid justify-items-center items-center">
                                            <input className="cursor-pointer" checked={(choice.type === 'someDays' && choice.days.includes(3))} onChange={(val) => updateDays(3) } type="checkbox" value="fromTomorrow"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="grid justify-items-center items-center">
                                            <input className="cursor-pointer" checked={(choice.type === 'someDays' && choice.days.includes(4))} onChange={(val) => updateDays(4) } type="checkbox" value="fromTomorrow"/>
                                        </div>
                                    </div>
                                    <div>
                                    <div className="grid justify-items-center items-center">
                                            <input className="cursor-pointer" checked={(choice.type === 'someDays' && choice.days.includes(5))} onChange={(val) => updateDays(5) } type="checkbox" value="fromTomorrow"/>
                                        </div>
                                    </div>
                                    <div>
                                    <div className="grid justify-items-center items-center">
                                            <input className="cursor-pointer" checked={(choice.type === 'someDays' && choice.days.includes(6))} onChange={(val) => updateDays(6) } type="checkbox" value="fromTomorrow"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="grid justify-items-center items-center">
                                            <input className="cursor-pointer" checked={(choice.type === 'someDays' && choice.days.includes(0))} onChange={(val) => updateDays(0) } type="checkbox" value="fromTomorrow"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    )
  }


interface OrderTypeCalendarProps {
    route?: any;
    choice: {type: string, days:number[]};
}

const now = new Date();
const tomorrow = addDays(now, 1);
const in3Days = addDays(now, 3);
const in5Days = addDays(now, 5);

const highlightedDates = [tomorrow, in3Days, in5Days];

function isSameDay(a: Date, b: Date) {
  return differenceInCalendarDays(a, b) === 0;
}

export const OrderTypeCalendar = (props: OrderTypeCalendarProps) => {
    const shop = useSelector(selectShop);
    let orderDay = new Date(shop.orderDay)
    console.log('Calendar')
    console.log(props.choice)

    const ifShowDayBefore = (shopOrderDay: string) => {
        // limite di ore nella giornata (1 -> fino all'1 di notte)
        const limitHours = 1
        const now = new Date()
        const orderDay = new Date(shopOrderDay)
        const theDayAfterTomorrow = new Date()
        const endOfToday = new Date()
        const timelimit = new Date()
        endOfToday.setHours(24,0,0,0)
        theDayAfterTomorrow.setDate(theDayAfterTomorrow.getDate() + 2)
        theDayAfterTomorrow.setHours(0,0,0,0)
        timelimit.setHours(0,0,0,0)
        timelimit.setTime(timelimit.getTime() + (limitHours*60*60*1000));
        // se l'ordine e' per dopodomani allora posso sempre tornare indietro a domani
        if (orderDay >= theDayAfterTomorrow) return true
        // se l'ordone e' per oggi allora non posso mai tornare indietro
        if (orderDay < endOfToday) return false
        if (now < timelimit) return true
        return false
      }
    
    function tileClassName({ date, view }: {date: Date, view: any}) {
        // se justTomorrow seleziono solamente orderDay
        // se fromTomorrow seleziono tutti i giorni a partire da orderDay
        // se someDays vado a vedere quali sono i giorni
        // if (
        //     view === 'month' &&
        //     highlightedDates.find((dDate) => isSameDay(dDate, date))
        // ) {
        //     return '!bg-orange-500 !text-black';
        // } return '!text-black'
        if (props.choice.type === "justTomorrow") {
            const selectedDate = orderDay
            if (view === 'month' && isSameDay(selectedDate, date)) {
                return '!bg-red-400 !text-black '
            }
        }
        if (props.choice.type === "fromTomorrow") {
            const selectedDate = getJustTomorrow().date
            if (view === 'month' && (compareAsc(selectedDate, date) === -1 || isSameDay(selectedDate, date))) {
                return '!bg-red-400 !text-black '
            }
        }
        if (props.choice.type === "someDays") {
            const selectedDate = getJustTomorrow().date
            if (view === 'month' && (compareAsc(selectedDate, date) === -1 || isSameDay(selectedDate, date))) {
                // controllo se il giorno e' quello giusto
                if (props.choice.days.includes(date.getDay()))
                    return '!bg-red-400 !text-black '
            }
        }
        
        return '!text-black'
    }

    const calendarMinDate = (ifShowDayBefore(new Date().toString()))? new Date(): new Date(new Date().setDate(new Date().getDate() + 1))
    return(<div>
        <Calendar 
            minDate={calendarMinDate}
            tileClassName={tileClassName}
            // click disable
            tileDisabled={() => true}
        />
    </div>)
}