export enum ProductTypes {
    unknown = "unknown",
}

export enum ProductOrderTypes {
    kg = "kg",
    piece = "piece",
    unknown = "unknown",
}

export class Image {
    name?: string;
    url?: string;
}

export class Product {
    "_id": string;
    "name": string;
    "type": ProductTypes;
    "ifSponsored": boolean;
    "ifVisible": boolean;
    "externalId": string;
    "size": number
    "ingredients"?: string    
    "orderType"?: ProductOrderTypes
    "deliveryTime"?: number;
    "deliverability"?: number
    "image"?: Image;
    "externalInfo"?: {
        "name": string
        "category"?: string
        "sku"?: string
        "size"?: string
        "typeDescription"?: string
        "description"?: string
        "additionalNote"?: string
        "ingredients"?: string
        "typology"?: string
    }    
}

export class LocalProduct extends Product {
    "quantityInCart": number;
    "quantityTot": number
    "order": {
        "normalQuantity": number
        "exceptionQuantity": number
        "totQuantity": number
    }
  }

export class UserNewOrderInput {
    "products" : {
        "id": string,
        "quantity": number
    }[]
    "note"?: string
}

// export class Order {
//     "_id": string;
//     "user" : {
//         "_id": string
//     }
//     "createdAt": Date;
//     "products" : {
//         "product": {
//             "_id": string
//         }
//         "quantity": number
//     }[]
// }

export class UserOrder {
    "date": string
    "ifClosed": boolean
    "products": {
        "product": Product
        "quantity": number
    }[]
    "exceptionsProducts": {
        "product": Product
        "quantity": number
    }[]
}

export class UserLimitedInfo {
    "_id": string;
    "email": string;
    "basicInfo": {
        "name": string
    }
}

export enum OrderType {
    justTomorrow = "justTomorrow",
    fromTomorrowOn = "fromTomorrowOn"
}

export class LocalOrderOrder {
    "products": UserOrderProduct[]
    "note": string
}

export class UserOrderProduct {
    "product": Product
    "quantity": number
}

export class  RemoteOrder {
    "note": String
    "date": Date
    "ifClosed": Boolean
    "products": [UserOrderProduct]
    "exceptionsProducts": [UserOrderProduct]
}

export class LocalOrder {
    "_id": string;
    "user"? : UserLimitedInfo
    "orderType": OrderType;
    "createdAt": Date;
    "order" : LocalOrderOrder
    "tomorrowOrderBeforeUpdate": RemoteOrder
    "tomorrowOrderAfterUpdate": RemoteOrder
}
