import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { LocalProduct, Product } from "../../storage/types";
import { useQuery } from "@apollo/client";
import { USER_PRODUCTS } from "../../storage/queries/product";
import { useEffect, useState } from "react";
import { translateOrderType } from "../../libs/toolbox";
import { TAppState } from "../../storage";
import { appState } from "../../storage/appState";
import { Textfit } from 'react-textfit';
import { LOG } from "../../config";
import { useDispatch } from "react-redux";
import { increaseProductQuantity, decreaseProductQuantity } from "../../storage/redux/shopSlice";
import { ProductCard } from "./Product";
import { Button, Loading } from "../../ui";

const log = LOG.extend('Views')

type CardSliderProps = {
    products: LocalProduct[]
    onInfo: (product: LocalProduct) => void
    isForDesktop: boolean
}

export const CardSlider = (props: CardSliderProps) => {
    log.debug('CardSlider')
    log.debug('Mapping products for carousel')
    const CarouselCards = props.products.map(
        (product, index) => 
        <div key={index}>
            <ProductCard bgColor="bg-red-400" product={product} onInfo={(product) => props.onInfo(product)} isForDesktop={props.isForDesktop}/>  
        </div>
    )
    if (CarouselCards.length === 0) {
        // Carousel con length 0 non parte in autoPlay
        return null
    }
    const cardSlider = (!props.isForDesktop) ?
        <div className=" p-2 h-32">
            <Carousel 
                key='carusel'
                showThumbs={false} 
                className="rounded-lg bg-red-500 "
                showIndicators={false}
                showStatus={false}
                autoPlay={true}
                stopOnHover={true}
                infiniteLoop={true}
                showArrows={false}
                >
                {CarouselCards}
            </Carousel>
        </div>: <div className=" p-2">
        <Carousel 
            key='carusel'
            showThumbs={false} 
            className="container shadow-2xl rounded-lg bg-red-500 "
            showIndicators={false}
            showStatus={false}
            autoPlay={true}
            stopOnHover={true}
            infiniteLoop={true}
            showArrows={false}
            >
            {CarouselCards}
        </Carousel>
    </div>
    return cardSlider
}

// export const CardSliderDesktop = (props: CardSliderProps) => {
//     log.debug('CardSliderDesktop')
//     log.debug('Mapping products for carousel')
//     const CarouselCards = props.products.map((product, index) => <div key={index}><ProductCardDesktop bgColor="bg-red-400" product={product} onInfo={(product) => props.onInfo(product)}/></div>)
//     if (CarouselCards.length === 0) {
//         // Carousel con length 0 non parte in autoPlay
//         return null
//     }
//     return <div className=" p-2">
//         <Carousel 
//             key='carusel'
//             showThumbs={false} 
//             className="container shadow-2xl rounded-lg bg-red-500 "
//             showIndicators={false}
//             showStatus={false}
//             autoPlay={true}
//             stopOnHover={true}
//             infiniteLoop={true}
//             showArrows={false}
//             >
//             {CarouselCards}
//         </Carousel>
//     </div>
// }