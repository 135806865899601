import { ReactNode, useEffect, useState } from "react";
import { LOG } from "../../config";
import { useSelector } from "react-redux";
import { selectShop } from "../../storage/redux/shopSlice";
import { ProductList } from "../../components/Product/ProductList";
import { calculateProductTotalFromOrder } from "../../libs/toolbox";
import { Button, TextBox } from "../../ui";
import { LocalProduct } from "../../storage/types";
import { RootState } from "../../storage/redux/store";
import { Link } from "react-router-dom";

const log = LOG.extend('ProductList')


interface AllProductsListViewProps {
    children?: ReactNode;
}

export const AllProductsListView = (props: AllProductsListViewProps) => {
    log.debug('AllProductsListViewProps')
    const shop = useSelector(selectShop) as RootState['shop'];
    const [products, setProducts] = useState<LocalProduct[]>([])
    const [selectedTags, setSelectedTags] = useState<string[]>([])
    const [searchText, setSearchText] = useState('')

    useEffect(()=> {
        log.debug('ProductList Effect on props.products')
        let products = shop.products
        // first filter by tags
        if (selectedTags.length > 0) {
            products = filterProductsByTags(products, selectedTags)
        }
        // second filter by search text
        if (searchText !== "") {
            products = filterProductsBySearch(products, searchText)
        }
        // third sort by preferred products
        setProducts(products)
    },[shop.products, searchText, selectedTags])

    if (!shop) {
        log.debug(`shop state not ready`)
        return <div>Caricamento prodotti...</div>
      }

    const onSearch = (text: string) => {
        setSearchText(text)
        if (text === "") {
            log.debug(`Search text empty`)
            setProducts(filterProductsByTags(shop.products, selectedTags))
        } else {
            log.debug(`Searching: ${text}`)
            log.debug(`Filtering products`)
            const filteredProducts = filterProductsByTags(shop.products, selectedTags).filter((product) => product.name.toLocaleLowerCase().includes(text.toLowerCase()))
            setProducts(filteredProducts)
        }
    }

    const filterProductsBySearch = (products: LocalProduct[], text: string) => {
        if (text === "") {
            log.debug(`Search text empty`)
            return products
        } else {
            log.debug(`Searching: ${text}`)
            log.debug(`Filtering products`)
            const filteredProducts = products.filter((product) => product.name.toLocaleLowerCase().includes(text.toLowerCase()))
            log.debug(`filtered: ${filteredProducts.length} products`)
            return filteredProducts
        }
    }

    const filterProductsByTags = (products: LocalProduct[], selectedTags: string[]) => {
        // il tag pasticceria identifica i prodotti che hanno come categoria PASTICCERIA
        // il tag pane identifica tutti gli altri prodotti
        // console.log(products.length)
        if (selectedTags.length === 0) return products
        // console.log(selectedTags)
        return products.filter((product) => {
            if (selectedTags.includes('PASTICCERIA')) {
                if (product.externalInfo?.category === 'PASTICCERIA') {
                    return true
                }
            }
            if (selectedTags.includes('PANE')) {
                if (product.externalInfo?.category !== 'PASTICCERIA') {
                    return true
                } else {
                    return false
                }
            }
            // product.externalInfo?.category && selectedTags.includes(product.externalInfo?.category))
            return false
        })
    }

    const toggleTag = (category: string) => {
        if (selectedTags.includes(category.toUpperCase())) {
            // remove tag 
            const foundIndex = selectedTags.findIndex((tag) => tag===category.toUpperCase())
            const newSelectedTags = [...selectedTags]
            newSelectedTags.splice(foundIndex,1)
            setSelectedTags(newSelectedTags)
            // setProducts(filterProductsByTags(filterProductsBySearch(shop.products, searchText), newSelectedTags))
        } else {
            // add tag
            const newSelectedTags = [...selectedTags, category.toUpperCase()]
            setSelectedTags(newSelectedTags)
            // setProducts(filterProductsByTags(filterProductsBySearch(shop.products, searchText), newSelectedTags))
        }
    }

    const categoriesList : string[] = ['PANE', 'PASTICCERIA']
    const categories = []
    for (const category of categoriesList) {
        categories.push(<div key={category}
            className={`bg-slate-200 px-2 cursor-pointer rounded-full ${(selectedTags.includes(category.toUpperCase()))? "bg-green-300": "bg-slate-300"}`}
            onClick={() => toggleTag(category)}
            >
            {category}
        </div>)
    }

    const sortedProducts = [...products].sort((a,b) => {
        if (!shop.preferredProducts) {
            return 0
        }
        if (shop.preferredProducts.includes(a._id) && !shop.preferredProducts.includes(b._id)) {
            return -1
        }
        if (!shop.preferredProducts.includes(a._id) && shop.preferredProducts.includes(b._id)) {
            return 1
        }
        return 0
    })

    return <div className="flex flex-col h-full">
        <div className="">
            <div className="p-2">
                <TextBox className="h-8" value={searchText} onChange={(event) => onSearch(event.target.value)} id='search' type="text" required={false} placeholder="Cerca..."/>
            </div>
            <div className="flex h-6 space-x-2 whitespace-nowrap mx-4">
                {categories}
            </div>
        </div>
        <div className="h-full overflow-y-scroll pb-20">
            <ProductList products={sortedProducts} />
        </div>
        <Link className='' to='/shop'>
            <div className="grid justify-items-center fixed bottom-2 w-full px-2">
                {/* <div className="flex bg-slate-200 px-16 py-4 text-center rounded-2xl text-2xl border-black opacity-90">
                    Concludi ordine
                </div> */}
                <Button className="max-w-md px-2" full size='lg' color="green" shadow onClick={()=> {}}>Vai al carrello</Button>
            </div>
        </Link>
    </div>
}