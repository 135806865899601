import { useDispatch, useSelector } from "react-redux";
import { enableTutorial, selectShop } from "../storage/redux/shopSlice";
import { LOG } from "../config";
import { Button } from "../ui";
import { useState } from "react";

interface TutorialSituationProps {
    actualPage: number
    totalPages: number
}

export const TutorialSituation = (props: TutorialSituationProps) => {
    const dots = []
    for (let i = 1; i <= props.totalPages; i++) {
        dots.push(<div key={i} className={`w-2 h-2 rounded-full ${i === props.actualPage ? 'bg-blue-500' : 'bg-gray-500'} mx-1`}></div>)
    }
    return <div className="flex justify-center items-center">
        {dots}
    </div>
} 

interface TutorialLayoutProps {
    prevPage?: () => void
    nextPage: () => void
    actualPage: number
    totalPages: number
    children: any
    imageUrl?: string
 }

export const TutorialLayout = (props:TutorialLayoutProps) => {
    const log = LOG.extend('Layout')
    const imageUrl = props.imageUrl || "./images/logo.png"
    return (
        <div className="">
            <div className="bg-white flex fixed top-0 w-full justify-center items-center md:grid-cols-2 h-80 ">
                <img className="object-contain h-full p-4 rounded-lg" src={imageUrl} />
            </div>
            <div className=" mt-80 mb-20 px-4 h-full">
                {props.children}
            </div>
            <div  className="h-20 fixed bottom-0 w-full bg-white p-5 text-2xl font-medium">
                <div className="flex items-center justify-around ">
                {
                    (props.prevPage) && (
                        <div className="flex cursor-pointer items-center justify-center" onClick={props.prevPage}>
                            {/* <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" /></svg>  */}
                            Indietro 
                        </div>
                    )
                }
                    <div className="flex cursor-pointer  items-center justify-center" onClick={props.nextPage}>
                        {
                            (props.actualPage === props.totalPages) ? 'Fine' : 'Avanti'
                        } 
                        {/* <svg className="w-6 h-6 pl-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" /></svg> */}
                    </div>
                </div>
                <div className="flex justify-center items-center pt-2">
                    <TutorialSituation actualPage={props.actualPage} totalPages={props.totalPages} />
                </div>
            </div> 
        </div>
    )
}


const log = LOG.extend('InitApp')
export const InitTutorial = (props: any) => {
    log.debug('InitTutorial')
    const dispatch = useDispatch();
    const shop = useSelector(selectShop);
    const [actualPage, setActualPage] = useState(1)
    console.log('shop', shop)
    const nextPage = () => {
        setActualPage(actualPage + 1)
        window.scrollTo(0, 0)
    }
    const prevPage = () => {
        setActualPage(actualPage - 1)
        window.scrollTo(0, 0)
    }

    const endTutorial = () => {
        console.log('concludi tutorial')
        setActualPage(1)
        window.scrollTo(0,0)
        dispatch(enableTutorial(false))
    }

    const page1 = <div className="flex flex-col">
        <div className="text-2xl text-center content-center grid justify-items-center pt-6">
            <div className="pt-2">
                Benvenuto in <span className="font-bold">Pandoper Ordini</span>
            </div>
        </div>
        <div className="grid content-center text-center text-xl">
        <p className="pt-2 leading-loose">
                Questa guida ti aiuterà a capire il funzionamento dell'applicazione.
            </p>
            <p className="leading-loose pt-2">
                Per iniziare, clicca su <span className="font-bold">Avanti</span>.
            </p>
        </div>
    </div>
    const page2 = <div className="flex flex-col">
            <div className="text-center text-xl">
                <div className="pb-2">
                        Nella <span className="font-bold">pagina home</span> vedrai l'ordine in corso, cioè l'ordine che riceverai nella giornata selezionata.
                </div>
                <div className="text-center">
                    Puoi selezionare un giorno differente utilizzando le frecce che trovi vicino al giorno.
                </div>
            </div>
            <div className="grid text-center text-xl">
                <div className="text-center">
                    Cliccando sul bottone "MODIFICA ORDINE" in basso sulla schermata potrai modificare l'ordine in corso.
                    L'applicazione ti porterà alla lista dei prodotti
                </div>
            </div>
    </div>
    const page3 = <div className="flex flex-col">
        <div className="grid text-center text-xl">
            <div className="text-center">
                Nella <span className="font-bold">lista prodotti</span> trovi tutti i prodotti ordinabili. Le quantità dell'ordine in corso sono già compilate.
                Puoi aumentare o diminuire la quantità di un prodotto cliccando sul bottone "+" o "-".
                Puoi inoltre aumentare o diminuire la quantità di 1 unità oppure di 5 unità per volta.
            </div>
            <div className="p-4 grid justify-items-center">
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            </div>
            <div className="text-center">
                Alcuni prodotti potrebbero essere non ordinabili in quanto è passato l'orario limite per tale ordine.
                Puoi cliccare sul bottone informativo in alto a destra di ogni prodotto per capire fino a quanto è possibile ordinare tale prodotto. 
            </div>
        </div>
        <div className="grid text-center text-xl">
            <div className="p-4 grid justify-items-center">
                <img src="./images/shoppingCart-64.png" width={32} className="rounded-lg"/>
            </div>
            <div className="text-center">
                Quando hai inserito tutte le quantità di prodotti che vuoi ricevere, clicca sull'icona carrello in alto a destra per andare a confermare l'ordine
            </div>
        </div>
    </div>

    const page4 = <div className="flex flex-col">
        <div className="grid text-center text-xl">
            <div className="text-center">
                Nella pagina di <span className="font-bold">conferma ordine</span> trovi il riepilogo di tutti i prodotti in cui hai impostato una quantità.
            </div>
        </div>
        <div className="grid text-center text-xl">
            <div className="text-center">
                Nella sezione <span className="font-bold">Tipo ordine</span> puoi decidere a quando si riferisce l'ordine.
            </div>
            <div>
                Infine, cliccando il tasto <span className="font-bold">CONCLUDI ORDINE</span> invierai l'ordine a Pandoper.
            </div>
        </div>
   </div>

    const lastPage = <div className="flex flex-col">
        <div className="grid text-center text-xl">
            <div>
                Il tutorial è concluso.
            </div>
            <div className="pt-4 grid justify-items-center">
                <img src="./images/user-192.png" width={32} height={32}/>
                Puoi rivedere questo <span className="font-bold">tutorial</span> in qualsiasi momento andando nella sezione profilo 
            </div>
            <div className="pt-4">
                Per qualsiasi problema o suggerimento puoi scrivere a support@onubo.com
            </div>
        </div>
    </div>

    if (shop.showTutorial !== false) {
        switch (actualPage) {
            case 1:
                return <TutorialLayout actualPage={actualPage} nextPage={nextPage} totalPages={5} imageUrl="./images/logo.png">{page1}</TutorialLayout>       
            case 2:
                return <TutorialLayout actualPage={actualPage} nextPage={nextPage} prevPage={prevPage} totalPages={5} imageUrl="./images/demo_home.png">{page2}</TutorialLayout>       
            case 3:
                return <TutorialLayout actualPage={actualPage} nextPage={nextPage} prevPage={prevPage} totalPages={5} imageUrl="./images/demo_product_list.png">{page3}</TutorialLayout>
            case 4:
                return <TutorialLayout actualPage={actualPage} nextPage={nextPage} prevPage={prevPage} totalPages={5} imageUrl="./images/demo_shop.png">{page4}</TutorialLayout>
            default:
                return <TutorialLayout actualPage={actualPage} nextPage={endTutorial} prevPage={prevPage} totalPages={5} imageUrl="./images/logo.png">{lastPage}</TutorialLayout>
        }
    }
    return <>{props.children}</>
}