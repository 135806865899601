import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { appStorage, TAppState } from "../storage";
import { APP_STATE } from "../storage/queries/appState";
import { USER_PRODUCTS } from "../storage/queries/product";
import { LocalProduct, Product, UserOrder } from "../storage/types";
import hash from 'object-hash'
import { appState as appStateUpdate } from "../storage/appState";
import { LOG } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { enableTutorial, initProducts, initProductsFromCache, selectShop } from "../storage/redux/shopSlice";
import { Button, Loading } from "../ui";
import { USER_ORDER } from "../storage/queries/order";

const log = LOG.extend('InitApp')

// download new bundle
// update the actual state
export const InitAppRefreshShopFromCloud = (props: any) => {
    log.info('InitAppRefreshShopFromCloud')
    const dispatch = useDispatch();
    const [synced, setSynced] = useState(false)
    let cacheShop = appStorage.getObject('shop') as TAppState
    if (cacheShop) {
        log.debug(`Got ${cacheShop.products.length} products from cache storage`)
    } else {
        log.warn(`No Cache found, initializing cache`)
        cacheShop = {
            products: [],
            originalProducts: [],
            dbVersion: "",
            showTutorial: true,
            lastUpdate: new Date().getTime(),
            preferredProducts: []
        }
        appStorage.setObject('shop',cacheShop)
    }

    const [getProduct, { loading, error, data }] = useLazyQuery<{ UserProducts: Product[] }>(USER_PRODUCTS, {
      fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        log.debug('Effect initializing')
        log.debug('Getting the products')
        getProduct()
    }, [])

    if (synced) {
        console.log('Synced')
        return <>{props.children}</>
    }

    if (loading) {
        log.debug('Loading Products')
        return <div className="grid place-content-center h-full">
            <Loading />
            <div>
                Caricamento Prodotti
            </div>
        </div>
    } 

    if (error) {
        log.error(`Got error while getting the products: ${error}`)
        if (cacheShop && cacheShop.products && cacheShop.dbVersion) {
            dispatch(initProductsFromCache({
                products: cacheShop.products, 
                dbVersion: cacheShop.dbVersion, 
                isOnline: true,
                showTutorial: cacheShop.showTutorial,
                preferredProducts: cacheShop.preferredProducts
            }))
            setSynced(true)
        } else
         return <div className="flex justify-center content-center">Errore di rete...</div>
    } 

    if (data && data.UserProducts) {
        log.debug(`Got ${data.UserProducts.length} products`)
        log.debug(`Cache dbVersion: ${cacheShop.dbVersion}`)
        const remoteDbVersion = hash(data.UserProducts)
        log.debug(`Remote dbVersion: ${remoteDbVersion}`)
        if (cacheShop.dbVersion !== remoteDbVersion) {
            log.debug('Need to update the cache')
            dispatch(initProducts({
                products: data.UserProducts.map((prod) => {return {...prod, quantityInCart:0, quantityTot: 0, order: {exceptionQuantity: 0, normalQuantity: 0, totQuantity: 0}}}), 
                dbVersion: remoteDbVersion, 
                isOnline: true,
                showTutorial: cacheShop.showTutorial,
                preferredProducts: cacheShop.preferredProducts
            }))
        } else {
            log.debug('Cache up to date')
            dispatch(initProductsFromCache({products: cacheShop.products, dbVersion: cacheShop.dbVersion, isOnline: true, showTutorial: cacheShop.showTutorial, preferredProducts: cacheShop.preferredProducts}))
        }
        setSynced(true)
    }


    // if (!shop || !shop.lastUpdate) {
    //     getProduct()
    // }

    return <>Syncing...</>
}

// initialize shop state from cache

export const InitApp = (props: any) => {
    log.info('Entering in InitApp')
    const shop = useSelector(selectShop);
    const dispatch = useDispatch();
    const [isInitializing, setIsInitializing] = useState(false)
    // const [getProduct, { loading, error, data }] = useLazyQuery<{ UserProducts: Product[] }>(USER_PRODUCTS, {
    //   fetchPolicy: 'cache-and-network',
    // });

    // useEffect(() => {
    //     log.debug('Effect initializing')
    //     log.debug('Getting the products')
    //     getProduct()
    // }, [isInitializing])

    if (shop && shop.dbVersion) {
        log.debug(`Shop dbversion: ${shop.dbVersion}`)
        return <>{props.children}</>
    }

    // if (loading) {
    //     log.debug('Loading Products')
    //     return <div className="flex justify-center content-center">Caricamento in corso</div>
    // } 

    // if (error) {
    //     log.error(`Got error while getting the products: ${error}`)
    //     return <div className="flex justify-center content-center">Riscontrato Errore...</div>
    // } 

    // if (data) {
    //     log.debug(`Got ${data.UserProducts.length} products`)
    //     dispatch(initProducts(data.UserProducts.map((prod) => {return {...prod, quantity:0}})))
    // }

    if (!shop) {
        log.debug(`shop state not ready`)
        return <div>Loading state...</div>
    }

    if (!shop.dbVersion) {
        log.debug('Empty DB')
        // dispatch(initProductsFromCache())
        return <div>Empty DB</div>
    }

    return <>{props.children}</>

}


