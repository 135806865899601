import { gql } from "@apollo/client";
import { UserNewOrderInput } from "../types";

export const NEW_ORDER = gql`
  mutation NEW_ORDER($order: UserNewOrderInput!, $date: Date!) {
    UserNewOrder(order: $order, date: $date) {
      success
      error
    }
  }
`;

export interface newOrderData {
  UserNewOrder: {
    success: boolean;
    error?: string;
  };
}
  
export interface newOrderVariables {
    order: UserNewOrderInput,
    date?: Date
}

export const NEW_ORDER_JUST_ONE_DAY = gql`
  mutation NEW_ORDER_JUST_ONE_DAY($order: UserNewOrderInput!, $date: Date!) {
    UserNewOrderJustOneDay(order: $order, date: $date) {
      success
      error
    }
  }
`;

export interface newOrderJustOneDayData {
  UserNewOrderJustOneDay: {
    success: boolean;
    error?: string;
  };
}
  
export interface newOrderJustOneDayVariables {
    order: UserNewOrderInput,
    date?: Date
}

export const NEW_ORDER_DAYS_OF_THE_WEEK = gql`
  mutation NEW_ORDER_DAYS_OF_THE_WEEK($order: UserNewOrderInput!, $days: [Int]) {
    UserNewOrderDaysOfTheWeek(order: $order, days: $days) {
      success
      error
    }
  }
`;

export interface newOrderDaysOfTheWeekData {
  UserNewOrderDaysOfTheWeek: {
    success: boolean;
    error?: string;
  };
}
  
export interface newOrderDaysOfTheWeekVariables {
    order: UserNewOrderInput,
    days: number[]
}

export const USER_ORDER = gql`
  query USER_ORDER($date: Date) {
    UserOrder(date: $date) {
      date
      ifClosed
      products {
        product {
          _id
          name
          type
          ifSponsored
          ifVisible
          size
          ingredients
          category
          orderType
          deliveryTime
          externalId
          image {
              url
              name
          }
          externalInfo {
            ingredients
            category
          }
        }
        quantity
      }
      exceptionsProducts {
        product {
          _id
          name
          type
          ifSponsored
          ifVisible
          size
          ingredients
          category
          orderType
          deliveryTime
          externalId
          image {
              url
              name
          }
          externalInfo {
            ingredients
            category
          }
        }
        quantity
      }
    }
  }
`;

export const USER_LAST_ORDERS = gql`
  query USER_LAST_ORDERS($limit: Int) {
    UserLastOrders(limit: $limit) {
      _id
      user {
        _id
        email
        basicInfo {
          name
        }
      }
      orderType
      createdAt
      order {
        products {
          product {
            _id
            name
            type
            ifSponsored
            ifVisible
            size
            ingredients
            category
            orderType
            deliveryTime
            image {
              url
              name
            }
            externalInfo {
              name
              category
              sku
              size
              typeDescription
              description
              additionalNote
              ingredients
              typology
            }
          }
          quantity
        }
      }
      tomorrowOrderBeforeUpdate {
        note
        date
        ifClosed
        products {
          product {
            _id
            name
            type
            ifSponsored
            ifVisible
            size
            ingredients
            category
            orderType
            deliveryTime
            image {
              url
              name
            }
            externalInfo {
              name
              category
              sku
              size
              typeDescription
              description
              additionalNote
              ingredients
              typology
            }
          }
          quantity
        }
        exceptionsProducts {
          product {
            _id
            name
            type
            ifSponsored
            ifVisible
            size
            ingredients
            category
            orderType
            deliveryTime
            image {
              url
              name
            }
            externalInfo {
              name
              category
              sku
              size
              typeDescription
              description
              additionalNote
              ingredients
              typology
            }
          }
          quantity
        }
      }
      tomorrowOrderAfterUpdate {
        note
        date
        ifClosed
        products {
          product {
            _id
            name
            type
            ifSponsored
            ifVisible
            size
            ingredients
            category
            orderType
            deliveryTime
            image {
              url
              name
            }
            externalInfo {
              name
              category
              sku
              size
              typeDescription
              description
              additionalNote
              ingredients
              typology
            }
          }
          quantity
        }
        exceptionsProducts {
          product {
            _id
            name
            type
            ifSponsored
            ifVisible
            size
            ingredients
            category
            orderType
            deliveryTime
            image {
              url
              name
            }
            externalInfo {
              name
              category
              sku
              size
              typeDescription
              description
              additionalNote
              ingredients
              typology
            }
          }
          quantity
        }
      }
    }
  }
`;
