import { gql } from '@apollo/client';

interface User {
  _id: string;
  email: string;
  basicInfo: {
    name: string;
  };
  auth: {
    createdAt: string;
    createdWith: string;
    isActive: boolean;
    isEmailVerified: boolean;
    permission: {
      scopes: string[];
    };
    lastLogin: string;
  };
}

const USER_ME = gql`
  query USER_ME {
    UserMe {
      _id
      email
      basicInfo {
        name
      }
      auth {
        createdAt
        createdWith
        isActive
        isEmailVerified
        permission {
          scopes
        }
        lastLogin
      }
    }
  }
`;

export { USER_ME };
export type { User };
